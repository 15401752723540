import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { paths } from 'common/const/urls';
import PageLayout from 'common/components/PageLayout';
import Main from 'scenes/Main';
import MainSpeciality from 'scenes/MainSpeciality';
import Additional from 'scenes/Additional';
import Contacts from 'scenes/Contacts';
import NotFound from 'scenes/NotFound';
import PrivacyPolicy from 'scenes/PrivacyPolicy';
import Custom from 'scenes/Custom';

function App() {
  const router = createBrowserRouter([
    {
      path: paths.MAIN,
      errorElement: <PageLayout inverted component={() => <NotFound />} />,
      children: [
        { path: paths.MAIN, element: <PageLayout component={() => <Main />} /> },
        {
          path: paths.MAIN_SPECIALITY,
          element: <PageLayout inverted component={() => <MainSpeciality />} />,
        },
        { path: paths.ADDITIONAL, element: <PageLayout component={() => <Additional />} /> },
        { path: paths.CUSTOM, element: <PageLayout inverted component={() => <Custom />} /> },
        { path: paths.CONTACTS, element: <PageLayout inverted component={() => <Contacts />} /> },
        { path: paths.PRIVACY_POLICY, element: <PageLayout inverted component={() => <PrivacyPolicy />} /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
