import { FunctionComponent } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  SUPPORT_EMAIL,
  REGISTRATION_ADDRESS,
  REGISTRATION_COUNTRY,
  INSTAGRAM,
  LINKED_IN,
} from 'common/const';
import {
  Wrapper,
  ContentWrapper,
  Container,
  Addresses,
  SocialNetworks,
  Email,
  Address,
  SocialNetwork,
} from './styles';

const Contacts: FunctionComponent = () => {
  return (
    <Wrapper>
      <Container>
        <DesktopContentWrapper>
          <ContentWrapper>
            <Addresses>
              <Email href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Email>
              <Address>
                <div>{REGISTRATION_COUNTRY}</div>
                <div>{REGISTRATION_ADDRESS}</div>
              </Address>
            </Addresses>
            <SocialNetworks>
              <SocialNetwork href={LINKED_IN} target="_blank">
                Linkedin
              </SocialNetwork>
              <SocialNetwork href={INSTAGRAM} target="_blank">
                Instagram
              </SocialNetwork>
            </SocialNetworks>
          </ContentWrapper>
        </DesktopContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default Contacts;
