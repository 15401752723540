import styled from 'styled-components';
import { devices } from 'common/const';
import _LetsTalk from 'common/components/LetsTalk';

export const Wrapper = styled.div.attrs({ className: 'primary-background primary-color' })`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media${devices.maxLaptopL} {
    flex-direction: column;
  }

  @media${devices.maxTablet} {
    gap: 10px;
    height: auto;
    padding-top: 150px;
    padding-bottom: 100px;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media${devices.maxLaptopL} {
    width: 80%;
    justify-content: space-between;
  }

  @media${devices.maxTablet} {
    gap: 6px;
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight xlarge-text secondary-background primary-color',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 128px;
  text-transform: uppercase;
  padding: 0 30px;

  @media${devices.maxTablet} {
    height: 72px;
    font-size: 26px;
  }
`;

export const LetsTalk = styled.button.attrs({
  className: 'primary-inverted-background standard-text semi-bold-weight',
})`
  width: 128px;
  height: 128px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #2f23f2;

  text-transform: uppercase;

  @media${devices.maxTablet} {
    width: 72px;
    height: 72px;
    font-size: 9px;
    line-height: 11.25px;
  }
`;

export const LetsTalkButton = styled(_LetsTalk).attrs({
  className: 'primary-inverted-background standard-text semi-bold-weight',
})`
  @media${devices.maxTablet} {
    width: 72px;
    height: 72px;
    font-size: 9px;
    line-height: 11.25px;
  }
`;
