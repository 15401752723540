import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import NotFoundLogo from 'common/components/NotFoundLogo';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import { Container, Wrapper, ContentWrapper, TextWrapper, Text, Button } from './styles';
import { paths } from 'common/const/urls';

const NotFound: FunctionComponent = () => {
  const navigate = useNavigate();
  const { result: isMobile } = useMedia(devices.maxTablet);

  return (
    <Wrapper>
      <Container>
        <DesktopContentWrapper>
          <ContentWrapper>
            {isMobile && <Text>Oh no... We lost this page</Text>}
            <NotFoundLogo />
            <TextWrapper>
              <Text>Oh no... We lost this page</Text>
              <Button onClick={() => navigate(paths.MAIN)}>Back to main</Button>
            </TextWrapper>
            {isMobile && <Button onClick={() => navigate(paths.MAIN)}>Back to main</Button>}
          </ContentWrapper>
        </DesktopContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default NotFound;
