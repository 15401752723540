import React, { FunctionComponent } from 'react';
import { Swiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ArrowIcon from 'common/components/ArrowIcon';
import { Wrapper, NavigationButton } from './styles';

type Props = {
  items: React.JSX.Element[];
};

const DesktopSwiper: FunctionComponent<Props> = ({ items }) => {
  const navigation = {
    nextEl: `.swiper-button-next `,
    prevEl: `.swiper-button-prev `,
  };

  return (
    <Wrapper>
      <NavigationButton className="swiper-button-prev">
        <ArrowIcon orientation="left" width={24} height={24} />
      </NavigationButton>
      <Swiper navigation={navigation} modules={[Navigation]}>
        {items}
      </Swiper>
      <NavigationButton className="swiper-button-next">
        <ArrowIcon width={24} height={24} />
      </NavigationButton>
    </Wrapper>
  );
};

export default DesktopSwiper;
