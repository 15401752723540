export const feedbacks = [
  {
    image: '/assets/feedback/rico.png',
    name: 'James',
    position: 'CEO',
    text: "Choosing ICECODE for our mobile app development was one of the best decisions we've made. The team went above and beyond to understand our requirements, providing innovative solutions to enhance user experience and functionality. Their Agile approach to development meant we were continually involved and could see our ideas coming to life throughout the process. The end product was user-friendly, aesthetically pleasing, and bug-free. Kudos to the entire team!",
  },
  {
    image: '/assets/feedback/wallet.png',
    name: 'Bohdan Abramov',
    position: 'COO',
    text: "We engaged ICECODE for a Web3 project “Crypto Wallet ''and were thoroughly impressed with their innovative solutions and technical expertise. The team was adept at navigating through the complexities of our requirements, providing insights and suggestions that enhanced the final product. Their systematic approach, regular updates, and adherence to timelines made the collaboration fruitful and enjoyable. We look forward to future projects with them!",
  },
  {
    image: '/assets/feedback/affpro.png',
    name: 'Vadym',
    position: 'Head AFFPRO',
    text: 'Embarking on our AdTech “AFFPRO” project with ICECODE was a journey of seamless collaboration and technical excellence. The team adeptly navigated through the complexities of developing our advertising technology platform, delivering not just a product, but a scalable solution that has significantly elevated our digital advertising capabilities. Their expertise in AdTech, transparent communication, and unwavering commitment to our project’s success have made ICECODE an invaluable development partner. Highly recommended for any AdTech endeavours!',
  },
  {
    image: '/assets/feedback/casa.png',
    name: 'Vitaliy',
    position: 'Co-founder ModernaCasa',
    text: 'Thanks to ICECODE’s work, the client saw higher user engagement on their online website. Users were able to find what they were looking for more easily due to the site’s design and work. The team managed the engagement well by delivering tasks on time and being highly responsive to the client.',
  },
];