import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;

  @media${devices.maxTablet} {
    gap: 12px;
  }
`;

export const Number = styled.div.attrs({
  className: 'secondary-background primary-color xlarge-text strong-weight',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-transform: uppercase;
  border-radius: 10px;

  @media${devices.maxTablet} {
    font-size: 30px;
  }
`;

export const Zero = styled.div`
  border-radius: 50%;
  width: 128px;
  border: 30px solid #4439f3;

  @media${devices.maxTablet} {
    width: 94px;
    border: 18px solid #4439f3;
  }
`;
