import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 447px;
  height: 447px;
  background: #f8f8f8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media${devices.maxTablet} {
    height: auto;
    width: auto;
    min-height: 447px;
  }
`;

export const Text = styled.div.attrs({
  className: 'standard-text semi-bold-weight',
})``;

export const BigImage = styled.img`
  margin-top: 20px;
`;

export const Title = styled.div.attrs({
  className: 'strong-weight semi-large-text',
})`
  margin: 30px 0;
  text-transform: uppercase;

  @media${devices.maxTablet} {
    margin: 16px 0;
    font-size: 26px;
  }
`;

export const Technologies = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const IconCross = styled.img.attrs({
  src: '/assets/cross.svg',
})`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;

  border-radius: 6px;
  background: rgba(255, 255, 255, 0.5);
`;

export const BodyText = styled.div.attrs({
  className: 'semi-bold-weight',
})`
  font-size: 12px;
  line-height: 16px;
  padding: 0 35px;
`;

export const Link = styled.a.attrs({ className: 'standard-text semi-bold-weight ', target: '_blank' })`
  color: #2f23f2 !important;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  padding: 5px 10px;
  position: absolute;
  top: 14px;
  left: 14px;
`;
