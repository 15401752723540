export const techCases = [
  {
    name: 'Backend',
    active: true,
    data: [
      {
        name: 'Languages',
        items: ['/assets/technologies/java.svg', '/assets/technologies/kotlin.svg'],
      },
      {
        name: 'Testing',
        items: [
          '/assets/technologies/jasmine.svg',
          '/assets/technologies/jest.svg',
          '/assets/technologies/karma.svg',
          '/assets/technologies/cypress.svg',
        ],
      },
      {
        name: 'Integrations',
        items: [
          '/assets/technologies/maps.svg',
          '/assets/technologies/google-maps.svg',
          '/assets/technologies/webrtc.svg',
          '/assets/technologies/img6.svg',
        ],
      },
    ],
  },
  {
    name: 'Frontend',
    active: false,
    data: [
      {
        name: 'Languages',
        items: [
          '/assets/technologies/css.svg',
          '/assets/technologies/html.svg',
          '/assets/technologies/js.svg',
          '/assets/technologies/ts.svg',
        ],
      },
      {
        name: 'Frameworks',
        items: [
          '/assets/technologies/angular.svg',
          '/assets/technologies/react-color.svg',
          '/assets/technologies/vue.svg',
        ],
      },
      {
        name: 'CSS libraries and frameworks',
        items: [
          '/assets/technologies/bootstrap.svg',
          '/assets/technologies/img1.svg',
          '/assets/technologies/img3.svg',
          '/assets/technologies/img4.svg',
        ],
      },
      {
        name: 'Testing',
        items: [
          '/assets/technologies/jasmine.svg',
          '/assets/technologies/jest.svg',
          '/assets/technologies/karma.svg',
          '/assets/technologies/cypress.svg',
        ],
      },
    ],
  },
  {
    name: 'IOS',
    active: false,
    data: [
      {
        name: 'General',
        items: [
          '/assets/technologies/objective-c.svg',
          '/assets/technologies/swift.svg',
          '/assets/technologies/img2.svg',
          '/assets/technologies/img5.svg',
        ],
      },
      {
        name: 'Work&Test',
        items: [
          '/assets/technologies/cocoapods.svg',
          '/assets/technologies/app-store.svg',
          '/assets/technologies/img7.svg',
          '/assets/technologies/firebase.svg',
        ],
      },
      {
        name: 'Cross-platform solutions',
        items: [
          '/assets/technologies/nativescript.svg',
          '/assets/technologies/ionic.svg',
          '/assets/technologies/react-color.svg',
        ],
      },
    ],
  },
  {
    name: 'Android',
    active: false,
    data: [
      {
        name: 'General',
        items: [
          '/assets/technologies/android-studio.svg',
          '/assets/technologies/android.svg',
          '/assets/technologies/flutter.svg',
          '/assets/technologies/firebase.svg',
        ],
      },
      {
        name: 'Integrations',
        items: [
          '/assets/technologies/webrtc.svg',
          '/assets/technologies/android-wear.svg',
          '/assets/technologies/google-maps.svg',
        ],
      },
      {
        name: 'Work&Test',
        items: [
          '/assets/technologies/cocoapods.svg',
          '/assets/technologies/app-store.svg',
          '/assets/technologies/img7.svg',
          '/assets/technologies/firebase.svg',
        ],
      },
      {
        name: 'Cross-platform solutions',
        items: [
          '/assets/technologies/nativescript.svg',
          '/assets/technologies/ionic.svg',
          '/assets/technologies/react-color.svg',
        ],
      },
    ],
  },
];
