import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LabelsWrapper = styled.div<{ $biggerWidth?: boolean }>`
  gap: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: ${({ $biggerWidth }) => ($biggerWidth ? 2 : 1)};

  @media${devices.maxLaptopL} {
    gap: 10px;
  }
`;

export const Label = styled.div.attrs({ className: 'standard-text semi-bold-weight' })`
  text-align: center;
  max-width: 238px;

  @media${devices.maxLaptopL} {
    font-size: 14px;
    max-width: 166px;
  }
`;

export const Title = styled.text.attrs({ className: 'medium-text semi-bold-weight' })<{
  $vertical?: boolean;
  $verticalInversely?: boolean;
}>`
  opacity: 0.6;

  ${({ $vertical, $verticalInversely }) =>
    $vertical &&
    `
      transform: ${$verticalInversely ? 'rotate(-90deg)' : 'rotate(90deg)'};
    `}

  @media${devices.maxLaptopL} {
    font-size: 12px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media${devices.maxLaptopL} {
    align-items: center;
  }
`;

export const LabelsRow = styled(Row)`
  padding: 30px 0;
  align-items: center;

  @media${devices.maxLaptopL} {
    padding: 20px 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div<{ $asColumn?: boolean; $rewert?: boolean }>`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;

  ${({ $asColumn }) => $asColumn && `flex-direction: column;`}
  ${({ $rewert }) => $rewert && `flex-direction: column-reverse;`}

  @media${devices.maxLaptopL} {
    gap: 20px;
    margin-bottom: 40px;
    width: 100%;
  }

  @media${devices.maxTablet} {
    gap: 10px;
    margin-bottom: 10px;
  }
`;

export const SideTitleElement = styled.div.attrs({
  className: 'secondary-background primary-color strong-weight',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 242px;
  border-radius: 170px;

  font-size: 24px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;

  @media${devices.maxLaptopL} {
    width: 190px;
    height: 100px;
    border-radius: 100px;
    font-size: 14px;
  }

  @media${devices.maxTablet} {
    width: 100%;
    height: 45px;
    border-radius: 100px;
  }
`;

export const TitleElement = styled.text.attrs({ className: 'secondary-background' })`
  width: 446px;
  height: 242px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media${devices.maxLaptopL} {
    width: 316px;
    height: 100px;
  }

  @media${devices.maxTablet} {
    width: 100%;
    height: 74px;
  }
`;

export const Divider = styled.span.attrs({ className: 'secondary-background' })`
  height: 1px;
  width: 100%;

  @media${devices.maxTablet} {
    margin: 20px 0;
  }
`;

export const CenterTitleDivider = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  border-left: 1px dashed #ffffff;

  transform: rotate(15deg);

  @media${devices.maxLaptopL} {
    transform: rotate(25deg);
  }

  @media${devices.maxTablet} {
    transform: rotate(15deg);
  }
`;
