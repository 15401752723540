import { FunctionComponent, memo } from 'react';

type Props = {
  color?: string;
  width?: number;
  height?: number;
  orientation?: 'left' | 'right';
};

const ArrowIcon: FunctionComponent<Props> = ({
  color = '#292D32',
  height = 20,
  width = 20,
  orientation = 'right',
}) => {
  return (
    <svg
      style={{ transform: orientation === 'right' ? 'scaleX(1)' : 'scaleX(-1)', width, height }}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42481 16.6011L12.8581 11.1678C13.4998 10.5261 13.4998 9.47612 12.8581 8.83446L7.4248 3.40112"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(ArrowIcon);
