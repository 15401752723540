import React, { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cross } from 'hamburger-react';
import { INSTAGRAM, LINKED_IN, SUPPORT_EMAIL } from 'common/const';
import { navigateToCustomerForm } from 'common/utils';
import {
  CallToAction,
  FooterLink,
  FooterWrapper,
  IconWrapper,
  Link,
  LinksWrapper,
  MenuWrapper,
} from './styles';

type Props = {
  menu: { name: string; path: string }[];
  inverted: boolean;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const HamburgerMenu: React.FunctionComponent<Props> = ({ menu, inverted, isOpen, setOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <IconWrapper $inverted={inverted}>
        <Cross direction="left" toggled={isOpen} toggle={setOpen} />
      </IconWrapper>
      <MenuWrapper $isOpen={isOpen}>
        <LinksWrapper>
          {menu.map(({ name, path }) => (
            <Link key={name} href={path}>
              {name}
            </Link>
          ))}
          <CallToAction onClick={() => navigateToCustomerForm(navigate, pathname)}>
            Call to action
          </CallToAction>
        </LinksWrapper>
        <FooterWrapper>
          <FooterLink href={INSTAGRAM}>Instagram</FooterLink>
          <FooterLink href={LINKED_IN}>Linkedin</FooterLink>
          <FooterLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</FooterLink>
        </FooterWrapper>
      </MenuWrapper>
    </>
  );
};

export default HamburgerMenu;
