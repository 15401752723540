import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 164px;
  padding-bottom: 250px;

  @media${devices.maxLaptopL} {
    padding-top: 144px;
    padding-bottom: 180px;
  }
  @media${devices.maxTablet} {
    padding-top: 128px;
    padding-bottom: 100px;
  }
`;

export const Container = styled.div.attrs({
  className: 'primary-inverted-color primary-inverted-background',
})`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 630px;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Date = styled.text.attrs({
  className: 'standard-text semi-bold-weight',
})`
  opacity: 0.5;
  margin-bottom: 24px;

  @media${devices.maxTablet} {
    margin-bottom: 10px;
  }
`;

export const Title = styled.text.attrs({
  className: 'strong-weight',
})`
  font-size: 60px;
  line-height: 102%;
  text-transform: uppercase;
  margin-bottom: 60px;

  @media${devices.maxTablet} {
    font-size: 30px;
    margin-bottom: 40px;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const BlockTitle = styled.text.attrs({
  className: 'strong-weight',
})`
  font-size: 30px;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;

  @media${devices.maxTablet} {
    font-size: 20px;
  }
`;

export const BlockTextParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
`;

export const BlockText = styled.text.attrs({
  className: 'standard-text semi-bold-weight',
})`
  @media${devices.maxTablet} {
    font-size: 14px;
  }
`;
