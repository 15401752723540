import { FunctionComponent, memo } from 'react';
import TableTemplate from 'common/components/TableTemplate';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  Container,
  LetsTalkWrapper,
  ModelSubtitle,
  ModelTitle,
  ModelTitleWrapper,
  Title,
  Wrapper,
} from './styles';
import LetsTalk from 'common/components/LetsTalk';

const Collaboration: FunctionComponent = () => {
  const models = [
    {
      title: (
        <ModelTitleWrapper>
          <ModelTitle>Team extension</ModelTitle>
          <ModelSubtitle>
            When facing a shortage of tech experts, exceeding deadlines or budgets.
          </ModelSubtitle>
        </ModelTitleWrapper>
      ),
      rows: [
        'Dedicated specialists',
        '1- 2 weeks to integrate',
        'No parallel projects. No context switching.',
      ],
    },
    {
      title: (
        <ModelTitleWrapper>
          <ModelTitle>Dedicated team</ModelTitle>
          <ModelSubtitle>
            A fully committed tech team as a turnkey solution for your software product.
          </ModelSubtitle>
        </ModelTitleWrapper>
      ),
      rows: [
        'Self-managed & multi-disciplinary',
        'Flexible (scale up/down)',
        'No parallel projects. No context switching.',
      ],
    },
    {
      title: (
        <ModelTitleWrapper>
          <ModelTitle>Custom solution</ModelTitle>
          <ModelSubtitle>
            Didn't find a model that fits your needs? Have a call with our tech experts.
          </ModelSubtitle>
        </ModelTitleWrapper>
      ),
      rows: [],
      rowElement: (
        <LetsTalkWrapper>
          <LetsTalk/>
        </LetsTalkWrapper>
      ),
      hideDivider: true,
    },
  ];

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>
            <text>Collaboration</text>
            <text>models</text>
          </Title>
          <TableTemplate columns={models} />
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Collaboration);
