import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({ className: 'primary-background primary-color' })`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;

  @media${devices.maxTablet} {
    padding-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 100px;

  @media${devices.maxLaptopL} {
    gap: 120px;
  }

  @media${devices.maxTablet} {
    gap: 60px;
  }
`;
