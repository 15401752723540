import { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  Container,
  Wrapper,
  Title,
  ServicesWrapper,
  Service,
  ServiceDescription,
  ServiceIndustry,
  ServiceTitle,
  GoToDesverce,
} from './styles';
import { DESVERSE_URL } from 'common/const';

const DesverseCollaboration: FunctionComponent = () => {
  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>
            <div>Design service</div>
            <div>from ICECODE x</div>
            <div>DESVERSE</div>
          </Title>
          <ServicesWrapper>
            <Service>
              <ServiceTitle>Web / App / Soft / UI/UX</ServiceTitle>
              <ServiceIndustry>UI/UX Design</ServiceIndustry>
              <ServiceDescription>
                Our UI/UX designers work to ensure that every digital product is not only functional and
                aesthetically pleasing but also maximally comfortable for users. They develop interfaces that
                promote high conversion and meet the needs of end-users, utilizing modern design techniques
                and trends.
              </ServiceDescription>
            </Service>
            <Service>
              <ServiceTitle>Digital Design</ServiceTitle>
              <ServiceIndustry>Digital Design</ServiceIndustry>
              <ServiceDescription>
                In the field of digital design, our studio offers a wide range of services: from graphic
                design and branding to 3D modeling and motion design. We create unique visual solutions that
                make your brand stand out and be remembered in the digital space, developing everything from
                logos and brand books to complex multimedia projects.
              </ServiceDescription>
            </Service>
          </ServicesWrapper>
          <GoToDesverce onClick={() => window.open(DESVERSE_URL, '_blank')}>go to desverse</GoToDesverce>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(DesverseCollaboration);
