import { DESVERSE_URL } from 'common/const';

export const cases = [
  {
    title: 'Modernacasa',
    bigImage: '/assets/cases/casa.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 210,
    industry: 'Real Estate',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: 'https://modernacasa.es',
  },
  {
    title: 'AFFPRO',
    bigImage: '/assets/cases/affpro.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 200,
    industry: 'AdsTech',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: 'https://aff-pro.com',
  },
  {
    title: 'DESVERSE',
    bigImage: '/assets/cases/desverse.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 280,
    industry: 'Commercial Tech',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: DESVERSE_URL,
  },
  {
    title: 'HomesBays',
    bigImage: '/assets/cases/homesBays.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 80,
    industry: 'Ecommerce',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: '',
  },
  {
    title: 'Petsomore',
    bigImage: '/assets/cases/pets.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 60,
    industry: 'Ecommerce',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: '',
  },
  {
    title: 'SportiHub',
    bigImage: '/assets/cases/sportiHub.webp',
    technologies: [
      '/assets/technologies/laravel.svg',
      '/assets/technologies/react.svg',
      '/assets/technologies/postgres.svg',
      '/assets/technologies/torch.svg',
    ],
    hours: 60,
    industry: 'Ecommerce',
    text: "MODERNACASA is a cutting-edge construction service that delivers safe, innovative, and high-quality engineering, home design, and building solutions. Our strength is the strict adherence to the core values which are efficiency, ingenuity, reliability, and customer-obsessed approach. We apply our employees' extensive experience, best practices, and modern technologies to optimize workflow, quality, and cost in construction project development and fulfillment. Our strategy of meeting the client's needs is based on a distinct methodology that involves expense and schedule benchmarking, design development, procurement of building materials, working process arranging, and bringing the most daring ideas into reality. Ambitious goals and near-impossible deadlines don't intimidate us since we are used to getting inspired by extraordinary challenges that are preferred to take up for exceptional results. Inventive projects need inclusion, creativity, and accuracy to be exceptional. That's why they require MODERNACASA.",
    link: '',
  },
];
