import { FunctionComponent } from 'react';
import DesverseLogo from 'common/components/DesverseLogo';
import DesverseCollaboration from 'common/components/DesverseCollaboration';
import DesverseCases from 'common/components/DesverseCases';
import DesverseJourney from 'common/components/DesverseJourney';
import DesverseCost from 'common/components/DesverseCost';
import { Wrapper } from './styles';

const Additional: FunctionComponent = () => {
  return (
    <Wrapper>
      <DesverseLogo />
      <DesverseCollaboration />
      <DesverseCases />
      <DesverseJourney />
      <DesverseCost />
    </Wrapper>
  );
};

export default Additional;
