import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { paths } from 'common/const/urls';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import {
  CallButton,
  Container,
  Logo,
  LogoText,
  LogoWrapper,
  MenuAndLogoWrapper,
  MenuDivider,
  MenuItem,
  MenuWrapper,
  Wrapper,
} from './styles';
import HamburgerMenu from './HamburgerMenu';
import { navigateToCustomerForm } from 'common/utils';

type Props = {
  inverted: boolean;
};

const Header: React.FunctionComponent<Props> = ({ inverted }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { result: isTablet, isMobile } = useMedia(devices.maxLaptop);

  const [dynamicInverted, setDynamicInverted] = useState(inverted);
  const [open, setOpen] = useState(false);

  const backgroundColor = dynamicInverted ? 'primary-inverted-background' : 'primary-background';
  const headerClasses = dynamicInverted ? 'primary-inverted-color' : 'primary-color';
  const menu = [
    {
      name: 'Main speciality',
      path: paths.MAIN_SPECIALITY,
    },
    {
      name: 'Additional',
      path: paths.ADDITIONAL,
    },
    {
      name: 'Custom',
      path: paths.CUSTOM,
    },
    {
      name: 'Contacts',
      path: paths.CONTACTS,
    },
  ];

  const goToMain = () => {
    if (pathname === paths.MAIN) {
      window.scrollTo(0, 0);
      setOpen(false);
      return;
    }

    navigate(paths.MAIN);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      setDynamicInverted(true);
    } else {
      document.body.style.overflow = 'auto';
      setDynamicInverted(inverted);
    }
  }, [open, inverted]);

  return (
    <Container className={backgroundColor}>
      <DesktopContentWrapper>
        <Wrapper className={headerClasses}>
          <MenuAndLogoWrapper>
            <MenuWrapper>
              {isTablet ? (
                <HamburgerMenu menu={menu} inverted={dynamicInverted} isOpen={open} setOpen={setOpen} />
              ) : (
                menu.map(({ name, path }, index) => (
                  <MenuItem key={name} href={path} $active={pathname === path} $inverted={dynamicInverted}>
                    {name}
                    {index !== menu.length - 1 && <MenuDivider>/</MenuDivider>}
                  </MenuItem>
                ))
              )}
            </MenuWrapper>
            <LogoWrapper onClick={goToMain}>
              <Logo src={dynamicInverted ? '/logoBlue.svg' : '/assets/logo-white.svg'} />
              {isTablet && <LogoText $inverted={dynamicInverted}>IceCode</LogoText>}
            </LogoWrapper>
          </MenuAndLogoWrapper>
          {!isMobile && (
            <CallButton onClick={() => navigateToCustomerForm(navigate, pathname)}>Call to action</CallButton>
          )}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default Header;
