import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'common/components/Header';
import CustomerForm from 'common/components/CustomerForm';
import Footer from 'common/components/Footer';
import ScrollToHashElement from 'common/components/ScrollToHashElement';
import { Container } from './styles';

type Props = {
  component: React.ComponentType<any>;
  inverted?: boolean;
};

const PageLayout: React.FunctionComponent<Props> = ({ component: RenderComponent, inverted = false }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <ScrollToHashElement />
      <Header inverted={inverted} />
      <RenderComponent />
      <CustomerForm />
      <Footer />
    </Container>
  );
};

export default PageLayout;
