import { memo } from 'react';
import VideoComponent from 'common/components/VideoComponent';
import { devices, MAIN_VIDEO } from 'common/const';
import { useMedia } from 'common/hooks/useMedia';
import { Group, Title, Wrapper, LetsTalkButton } from './styles';

const Welcome = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);

  return (
    <Wrapper>
      <Group>
        <Title>Icecode</Title>
        <VideoComponent
          url={MAIN_VIDEO}
          placeholder="/assets/welcome-bg.webp"
          width={isMobile ? '155px' : '256px'}
          height={isMobile ? '72px' : '128px'}
          aspectRatio={isMobile ? '3.3/1.8' : 'auto'}
          borderRadius="90px"
          iframeFullWidth
        />
      </Group>
      <Group>
        <Title>in trend tech</Title>
        <LetsTalkButton />
      </Group>
    </Wrapper>
  );
};

export default memo(Welcome);
