import { CSSProperties, FunctionComponent, useMemo, useRef } from 'react';
import { DragOptions, useDraggable } from '@neodrag/react';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import { Image, Wrapper } from './styles';

type Props = {
  onDrag: () => void;
  onTop: boolean;
  src: string;
  position: {
    desktop: { x: number; y: number };
    tablet: { x: number; y: number };
    mobile: { x: number; y: number };
  };
  sizes: {
    desktop: {
      width: string;
      height: string;
    };
    tablet: {
      width: string;
      height: string;
    };
    mobile: {
      width: string;
      height: string;
    };
  };
  customStyles?: CSSProperties;
};

const DraggableImage: FunctionComponent<Props> = ({ sizes, src, onTop, position, onDrag, customStyles }) => {
  const { isTablet, isLaptopL, isDesktop } = useMedia(devices.maxLaptopL);
  const { result: isMobile } = useMedia(devices.maxTablet);

  const draggableRef = useRef(null);

  const options: DragOptions = useMemo(
    () => ({
      applyUserSelectHack: false,
      defaultPosition: {
        x: isDesktop
          ? position.desktop.x
          : isTablet && isLaptopL
          ? position.tablet.x
          : isMobile
          ? position.mobile.x
          : 0,
        y: isDesktop
          ? position.desktop.y
          : isTablet && isLaptopL
          ? position.tablet.y
          : isMobile
          ? position.mobile.y
          : 0,
      },
    }),
    [position, isDesktop, isTablet, isLaptopL, isMobile],
  );

  useDraggable(draggableRef, options);

  return (
    <Wrapper ref={draggableRef} onMouseDown={onDrag} onTouchStart={onDrag} $isDragging={onTop}>
      <Image
        $desktop={sizes.desktop}
        $tablet={sizes.tablet}
        $mobile={sizes.mobile}
        src={src}
        style={customStyles}
      />
    </Wrapper>
  );
};

export default DraggableImage;
