import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateToCustomerForm } from 'common/utils';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  CallButton,
  Container,
  Filter,
  FilteredListWrapper,
  Filters,
  ImagesWrapper,
  Item,
  ItemsWrapper,
  Title,
  Wrapper,
  ImageWrapper,
} from './styles';
import { techCases } from './const';

const Technologies: FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeItems, setActiveItems] = useState(techCases);

  const handleChangeFilter = useCallback(
    (name: string) => {
      const filtered = activeItems.map((item) =>
        item.name === name
          ? { ...item, active: true }
          : {
              ...item,
              active: false,
            },
      );
      setActiveItems(filtered);
    },
    [activeItems],
  );

  const activeData = useMemo(() => {
    return activeItems.find((data) => data.active)?.data;
  }, [activeItems]);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>
            <text>Our</text>
            <text>Technologies</text>
          </Title>
          <FilteredListWrapper>
            <Filters>
              {activeItems.map((item) => (
                <Filter
                  key={item.name}
                  className={item.active ? 'button-hover-border' : 'button-hover-border-inverted'}
                  $isActive={item.active}
                  onClick={() => handleChangeFilter(item.name)}
                >
                  {item.name}
                </Filter>
              ))}
            </Filters>
            <ItemsWrapper>
              {activeData &&
                activeData.map((data, index) => (
                  <motion.div
                    key={`${data.name}_${index}`}
                    initial={{ x: '100%' }}
                    animate={{ x: '0' }}
                    transition={{ type: 'spring', x: { duration: 0.3 } }}
                  >
                    <Item>
                      <text>{data.name}</text>
                      <ImagesWrapper>
                        {data.items.map((item) => (
                          <ImageWrapper key={item}>
                            <img src={item} alt={item} />
                          </ImageWrapper>
                        ))}
                      </ImagesWrapper>
                    </Item>
                  </motion.div>
                ))}
            </ItemsWrapper>
          </FilteredListWrapper>
          <CallButton onClick={() => navigateToCustomerForm(navigate, pathname)}>
            call to action form
          </CallButton>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Technologies);
