import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media${devices.maxTablet} {
    height: auto;
    padding-top: 176px;
    padding-bottom: 126px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  @media${devices.maxLaptopL} {
    width: 90%;
    gap: 20px;
  }

  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const LogoImg = styled.img.attrs({
  src: '/assets/desverse/logo.webp',
})`
  object-fit: cover;

  @media${devices.maxLaptopL} {
    width: 219px;
    height: 128px;
    border-radius: 100px;
  }

  @media${devices.maxTablet} {
    width: 78px;
    height: 78px;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight',
})`
  text-transform: uppercase;
  font-size: 80px;
  line-height: 102%;

  @media${devices.maxLaptopL} {
    font-size: 52px;
  }

  @media${devices.maxTablet} {
    font-size: 26px;
  }
`;
