import { FunctionComponent } from 'react';
import { Container, Item, Title, Text } from './styles';

const Benefits: FunctionComponent = () => {
  const benefits = [
    {
      title: 'Synchronised for Success',
      text: [
        <text>Dedicated Teams Tailored for Your Needs</text>,
        <text>Customized Terms for Various Budgets</text>,
        <text>Experts Ready to Join Your Venture</text>,
      ],
    },
    {
      title: 'ELITE OF DEVELOPERS UNDER ONE ROOF',
      text: [
        <text>Tailored Team Solutions</text>,
        <text>Solutions for Every Budget</text>,
        <text>Pioneers in Tech Ready to Collaborate</text>,
      ],
    },
    {
      title: 'POOOOOL OF SPECIALISTS',
      text: [
        <text>Your Perfect Team Awaits</text>,
        <text>Flexibility for Big and Small Budgets</text>,
        <text>Specialists Turning Ideas into Reality</text>,
      ],
    },
  ];

  return (
    <Container>
      {benefits.map((benefit, index) => (
        <Item key={benefit.title}>
          <Title>{benefit.title}</Title>
          <Text>{benefit.text}</Text>
        </Item>
      ))}
    </Container>
  );
};

export default Benefits;
