import styled from 'styled-components';
import { dimensions } from 'common/const';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DesktopContentWrapper = styled.div`
  max-width: ${dimensions.desktopContentWidth}px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  position: relative;
`;
