export const questions = [
  {
    title: 'What services does your company provide for businesses?',
    text: 'Our studio offers a comprehensive range of digital solutions tailored for businesses of all sizes. Our services include Web Development, creating responsive and user-friendly websites; Software Development, building robust software systems and APIs; App Development, designing both iOS and Android mobile applications; UI/UX Design, crafting intuitive and engaging user experiences; Digital Design, offering graphic and motion design; and specialized services like Dedicated Team models and flexible conditions for startups. We aim to provide end-to-end solutions that resonate with your business goals.',
  },
  {
    title: 'How do App and Web Projects differ at your company?',
    text: 'Web projects typically involve creating websites or web applications that run in a browser, be it corporate websites, e-commerce platforms, or landing pages. App projects, on the other hand, pertain to mobile applications designed for specific operating systems like iOS and Android. While both share the essence of creating digital interfaces, they cater to different user behaviors, platforms, and technical requirements. Our team ensures that the right approach and tools are used according to the nature of the project.',
  },
  {
    title: 'Which technologies do you leverage for Web, Software, and App development?',
    text: " We harness a multitude of modern technologies tailored to each project's specific requirements. For web development, we use a blend of front-end and back-end technologies such as HTML5, CSS3, JavaScript frameworks, and popular server-side frameworks. Software development projects might involve languages and platforms like Java, Python, .NET, and others. For mobile app development, we specialize in both native (Swift for iOS, Kotlin for Android) and cross-platform technologies. Our primary goal is to use the right tool for the job, ensuring optimal performance and scalability.",
  },
  {
    title: 'How is the cost for Web, Software, or App Development determined?',
    text: ' Project costs are determined based on several factors: the complexity of the project, the technologies used, the number of hours required, and any additional services like UI/UX design or post-launch support. We start with an in-depth consultation to understand your requirements, based on which a detailed proposal and cost breakdown is provided. For larger budget projects, we also offer a tailored pricing structure ensuring you get the best value for your investment.',
  },
  {
    title: 'Do you offer support post-development?',
    text: 'Absolutely! We believe in building lasting relationships. Post-development support is a crucial aspect of our services, ensuring the smooth operation of the digital products we deliver. This includes troubleshooting, regular updates, optimizations, and any necessary adjustments as per the evolving needs of the project.',
  },
];
