import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.button.attrs({
  className: 'primary-inverted-background standard-text semi-bold-weight button-hover-inverted',
})<{ $hovered: boolean }>`
  width: 128px;
  height: 128px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #2f23f2;

  ${({ $hovered }) =>
    $hovered &&
    `
    font-size: 12px;
    line-height: 14px
  `}

  @media${devices.maxTablet} {
    width: 100px;
    height: 100px;
    font-size: 14px;
    line-height: 20px;
  }
`;
