import styled from 'styled-components';
import { devices } from 'common/const';

type CustomStyles = {
  $customStyles?: string;
};

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;

  @media${devices.tablet} {
    overflow-x: hidden;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 200px;

  @media${devices.maxLaptopL} {
    align-items: center;
    width: 90%;
    gap: 140px;
  }

  @media${devices.maxTablet} {
    gap: 60px;
  }
`;

export const ArticleWrapper = styled.div<{ $row?: boolean } & CustomStyles>`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: ${({ $row }) => ($row === true ? 'row' : 'column')};

  ${({ $customStyles }) => $customStyles};
`;

export const TitleWrapper = styled.div<{ $row?: boolean } & CustomStyles>`
  display: flex;
  flex-direction: ${({ $row }) => ($row === true ? 'row' : 'column')};
  gap: 20px;
  align-items: flex-start;

  ${({ $customStyles }) => $customStyles};
`;

export const Subtitle = styled.div.attrs({
  className: 'secondary-background primary-color standard-text semi-bold-weight',
})<{ $vertical?: boolean }>`
  padding: 4px 14px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ $vertical }) =>
    $vertical === true &&
    `
   transform: rotate(-90deg) translate(-40%, 140%);
  `}
`;

export const Text = styled.div.attrs({
  className: 'medium-text semi-bold-weight',
})<CustomStyles>`
  opacity: 0.8;
  line-height: normal;
  ${({ $customStyles }) => $customStyles};

  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;

export const TitleWordsGroup = styled.div<CustomStyles>`
  display: flex;
  flex-direction: column;
  ${({ $customStyles }) => $customStyles};

  @media${devices.maxLaptopL} {
    align-items: center;
  }

  @media${devices.maxTablet} {
    overflow: hidden;
    align-items: flex-start;
    width: 100%;
  }
`;

export const TitlePart = styled.text.attrs({
  className: 'xhuge-text strong-weight',
})`
  text-transform: uppercase;
  line-height: 150px;
  letter-spacing: -5px;

  @media${devices.maxTablet} {
    font-size: 70px;
    line-height: 102%;
    letter-spacing: normal;
  }
`;

export const TitlePartImage = styled.div`
  background-image: url('/assets/article/titlePart.webp');
  border-radius: 100px;
  width: 177px;
  height: 128px;
  display: inline-block;

  @media${devices.maxTablet} {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background-size: 100% 100%;
    margin-left: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const LastImages = styled.div`
  display: flex;
  gap: 10px;

  @media${devices.maxLaptopL} {
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-bottom: 60px;
  }

  @media${devices.maxTablet} {
    gap: 10px;
    margin-bottom: 30px;
  }
`;

export const ImageWrapper = styled.div`
  width: 140px;
  height: 376px;
  border-radius: 180px;

  @media${devices.maxLaptopL} {
    border-radius: 50%;
    width: 140px;
    height: 140px;
    background-size: 100% 100%;
  }

  @media${devices.maxTablet} {
    width: 155px;
    height: 155px;
  }
`;

export const Meme = styled.img<CustomStyles>`
  position: absolute;
  border-radius: 20px;
  border: 10px solid #fff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
  ${({ $customStyles }) => $customStyles};

  @media${devices.maxLaptopL} {
    border: 8px solid #fff;
  }

  @media${devices.maxTablet} {
    border: 4px solid #fff;
    border-radius: 10px;

    display: none;
  }
`;
