import styled from 'styled-components';
import { devices } from 'common/const';
import { LetsTalk } from 'common/components/Welcome/styles';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 200px;

  @media${devices.maxLaptopL} {
    padding-bottom: 100px;
  }
  @media${devices.maxTablet} {
    padding-bottom: 80px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'xhuge-text strong-weight',
})`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 100px;

  @media${devices.maxLaptopL} {
    font-size: 100px;
    margin-bottom: 93px;
  }
  @media${devices.maxTablet} {
    font-size: 44px;
    margin-bottom: 40px;
  }
`;

export const ServicesWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media${devices.maxLaptopL} {
    flex-direction: column;
  }
`;

export const Service = styled.div.attrs({
  className: 'secondary-background',
})`
  flex: 1;
  padding: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  @media${devices.maxLaptopL} {
    padding: 40px;
  }

  @media${devices.maxTablet} {
    padding: 30px;
  }
`;

export const ServiceTitle = styled.div.attrs({
  className: 'semi-large-text strong-weight',
})`
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;

  @media${devices.maxTablet} {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const ServiceIndustry = styled.div.attrs({
  className: 'standard-text semi-bold-weight',
})`
  border-radius: 40px;
  border: 1px solid #fff;
  padding: 4px 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 14px;

  @media${devices.maxTablet} {
    font-size: 14px;
    margin-bottom: 12px;
    padding: 4px 22px;
  }
`;

export const ServiceDescription = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})`
  text-align: center;
`;

export const GoToDesverce = styled(LetsTalk)`
  text-align: center;

  @media${devices.laptopL} {
    transform: translateY(-50%);
  }

  @media${devices.maxLaptopL} {
    margin-top: 30px;
  }

  @media${devices.maxTablet} {
    width: 100px;
    height: 100px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 40px;
  }
`;
