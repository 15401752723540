import { Fragment, FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { ModelTitleWrapper, ModelSubtitle, ModelTitle } from 'common/components/Collaboration/styles';
import TableTemplate from 'common/components/TableTemplate';
import { Divider } from 'common/components/TableTemplate/styles';
import { Container, Wrapper, Title, RowWrapper, RowsWrapper, Button } from './styles';

const DesverseCost: FunctionComponent = () => {
  const costs = [
    {
      title: 'Simple',
      subtitle:
        'Ideal for startups or small businesses looking for a clean and functional design for their software product.',
      rows: [
        ['Duration', '2 - 4 weeks'],
        ['Price', '20.000 USD'],
      ],
      button: 'Choose simple',
    },
    {
      title: 'Advanced',
      subtitle:
        'Suitable for businesses that require a more comprehensive design solution with enhanced features and user experience.',
      rows: [
        ['Duration', '5 - 7 weeks'],
        ['Price', '35.000 USD'],
      ],
      button: 'Select advanced',
    },
    {
      title: 'Complex',
      subtitle:
        'Tailored for large-scale projects that require detailed design solutions and have complex user requirements.',
      rows: [
        ['Duration', '8+ weeks'],
        ['Price', '45.000+ USD'],
      ],
      button: 'Go complex',
    },
  ];

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>Cost matters</Title>
          <TableTemplate
            columns={costs.map(({ title, subtitle, rows, button }) => ({
              title: (
                <ModelTitleWrapper>
                  <ModelTitle>{title}</ModelTitle>
                  <ModelSubtitle style={{ maxWidth: '292px' }}>{subtitle}</ModelSubtitle>
                </ModelTitleWrapper>
              ),
              rows: [],
              rowElement: (
                <RowsWrapper>
                  {rows.map((row, index) => (
                    <Fragment key={`${title}_${index}`}>
                      <RowWrapper>
                        {row.map((item) => (
                          <div key={item}>{item}</div>
                        ))}
                      </RowWrapper>
                      <Divider />
                    </Fragment>
                  ))}
                  <Button>{button}</Button>
                </RowsWrapper>
              ),
            }))}
          />
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(DesverseCost);
