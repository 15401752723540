import { FunctionComponent } from 'react';
import CustomPageLogo from 'common/components/CustomPageLogo';
import Gazette from 'common/components/Gazette';
import { Wrapper } from './styles';

const Custom: FunctionComponent = () => {
  return (
    <Wrapper>
      <CustomPageLogo />
      <Gazette />
    </Wrapper>
  );
};

export default Custom;
