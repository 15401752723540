import { FunctionComponent } from 'react';
import ArrowIcon from 'common/components/ArrowIcon';
import { Image, Wrapper, Button, Icon } from './styles';

type Props = {
  image: string;
  name: string;
};

const Case: FunctionComponent<Props> = ({ image, name }) => {
  return (
    <Wrapper>
      <Image src={image} />
      <Button>
        {name}
        <Icon>
          <ArrowIcon color="#292d32" />
        </Icon>
      </Button>
    </Wrapper>
  );
};

export default Case;
