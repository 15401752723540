import { Fragment, FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  useCustomerForm,
  ALLOWED_FILE_EXTENSIONS,
  FILE_SIZE_LIMIT_IN_MB,
} from 'common/hooks/useCustomerForm';
import { CUSTOMER_FORM_ID } from 'common/const';
import {
  Button,
  ButtonWrapper,
  Container,
  FileInput,
  FileText,
  FileTitle,
  Form,
  Input,
  InputLine,
  PhoneNumberInput,
  TextInput,
  Title,
  Wrapper,
  CrossIcon,
  FileStatus,
} from './styles';

const CustomerForm: FunctionComponent = () => {
  const {
    formRef,
    hiddenFileInput,
    data,
    isFileUploading,
    fileName,
    onNameChange,
    onCompanyChange,
    onDetailsChange,
    onBudgetChange,
    onPhoneNumberChange,
    onFileChange,
    onEmailChange,
    handleSubmit,
    removeFile,
    isFileUploadingFailed,
  } = useCustomerForm();

  return (
    <Container id={CUSTOMER_FORM_ID}>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>Start a project</Title>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputLine>
              <Input type={'name'} placeholder={'Full name *'} onChange={onNameChange} required />
            </InputLine>
            <InputLine>
              <Input type={'email'} placeholder={'Email *'} onChange={onEmailChange} required />
              <PhoneNumberInput
                international
                defaultCountry={'UA'}
                placeholder={'Phone number'}
                value={data.phoneNumber}
                onChange={onPhoneNumberChange}
              />
            </InputLine>
            <InputLine>
              <Input type={'name'} placeholder={'Company'} onChange={onCompanyChange} required />
              <Input type={'search'} placeholder={'Budget'} onChange={onBudgetChange} required />
            </InputLine>
            <InputLine>
              <TextInput placeholder={'Project details'} onChange={onDetailsChange} />
              <FileInput onClick={() => hiddenFileInput.current && hiddenFileInput.current.click()}>
                {fileName && !isFileUploading ? (
                  <Fragment>
                    <div>
                      <FileTitle>{fileName}</FileTitle>
                      <CrossIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFile();
                        }}
                      />
                    </div>
                    <FileStatus $failed={isFileUploadingFailed as boolean}>
                      {isFileUploadingFailed ? 'File uploading failed' : 'Uploaded successfully'}
                    </FileStatus>
                  </Fragment>
                ) : (
                  <Fragment>
                    <FileTitle>Accepted File Types:</FileTitle>
                    <FileText>{`${ALLOWED_FILE_EXTENSIONS} up to ${FILE_SIZE_LIMIT_IN_MB}MB`}</FileText>
                  </Fragment>
                )}
                <input
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={onFileChange}
                />
              </FileInput>
            </InputLine>
            <ButtonWrapper>
              <Button disabled={isFileUploading} type={'submit'}>
                {isFileUploading ? 'Uploading...' : 'Send'}
              </Button>
            </ButtonWrapper>
          </Form>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(CustomerForm);
