import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 200px;

  @media${devices.maxLaptopL} {
    padding-bottom: 100px;
  }

  @media${devices.maxTablet} {
    padding-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight',
})`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 140px;
  font-size: 80px;

  @media${devices.maxLaptopL} {
    margin-bottom: 80px;
  }
  @media${devices.maxTablet} {
    font-size: 44px;
    margin-bottom: 60px;
  }
`;

export const RowsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const RowWrapper = styled.div.attrs({ className: 'standard-text semi-bold-weight' })`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;

  @media${devices.maxLaptopL} {
    font-size: 14px;
  }
`;

export const Button = styled.button.attrs({
  className: 'standard-text strong-weight button-hover-border-inverted',
})`
  margin-top: 30px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ffffff;
  color: #2f23f2;

  padding: 13px 29px;
  text-transform: uppercase;

  @media${devices.maxTablet} {
    width: 100%;
  }
`;
