import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 200px;
  margin-bottom: 150px;

  @media${devices.maxLaptopL} {
    padding-bottom: 100px;
    margin-bottom: 100px;
  }
  @media${devices.maxTablet} {
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 100px;

  @media${devices.maxLaptopL} {
    flex-direction: column;
    gap: 60px;
    width: 90%;
  }
  @media${devices.maxTablet} {
    gap: 30px;
  }
`;

export const Title = styled.text.attrs({
  className: 'xhuge-text strong-weight',
})`
  text-transform: uppercase;
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media${devices.maxLaptopL} {
    width: 100%;
  }
  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const Item = styled.div`
  display: flex;
  gap: 19px;
  cursor: pointer;

  @media${devices.maxLaptopL} {
    width: 100%;
  }
`;

export const ItemBody = styled.div.attrs({
  className: 'secondary-background',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  width: 784px;
  height: fit-content;
  border-radius: 10px;

  @media${devices.maxLaptopL} {
    padding: 20px;
    flex: 2;
  }
  @media${devices.maxTablet} {
    padding: 14px 8px 14px 14px;
  }
`;

export const ItemTitle = styled.text.attrs({
  className: 'medium-text semi-bold-weight',
})<{ $isHidden: boolean }>`
  transition: margin-bottom 0.3s ease;
  ${({ $isHidden }) => $isHidden && 'opacity: 0.8'};
  ${({ $isHidden }) => !$isHidden && 'margin-bottom: 20px'};

  @media${devices.maxTablet} {
    font-size: 14px;
    padding-right: 28px;
    ${({ $isHidden }) => !$isHidden && 'margin-bottom: 13px'};
  }
`;

export const ItemText = styled.text.attrs({
  className: 'small-text semi-bold-weight',
})`
  opacity: 0.8;
`;

export const ButtonWrapper = styled.div.attrs({
  className: 'secondary-background',
})<{ $isHidden: boolean }>`
  width: 64px;
  border-radius: ${({ $isHidden }) => (!$isHidden ? '80px' : '50%')};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isHidden }) => !$isHidden && 'background: #FFF'};
`;

export const Image = styled.img`
  width: 24px;
  height: auto;
`;

export const MobileImage = styled.img`
  position: absolute;
  top: 18px;
  right: 12px;
  width: 16px;
  height: auto;
`;
