import { memo } from 'react';
import { useMedia } from 'common/hooks/useMedia';
import { devices, MAIN_VIDEO } from 'common/const';
import VideoComponent from 'common/components/VideoComponent';
import { Group, Text, Wrapper } from './styles';

const CustomPageLogo = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);

  return (
    <Wrapper>
      <Group>
        <Text>CUSTOM</Text>
        <VideoComponent
          url={MAIN_VIDEO}
          placeholder="/assets/welcome-bg.webp"
          width={isMobile ? '155px' : '256px'}
          height={isMobile ? '72px' : '128px'}
          aspectRatio={isMobile ? '3.3/1.8' : 'auto'}
          borderRadius="90px"
          iframeFullWidth
        />
        {!isMobile && <Text>ICECODE</Text>}
      </Group>
      {isMobile && <Text>ICECODE</Text>}
    </Wrapper>
  );
};

export default memo(CustomPageLogo);
