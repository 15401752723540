import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 250px;

  @media${devices.maxLaptopL} {
    padding-bottom: 160px;
  }

  @media${devices.maxTablet} {
    padding-bottom: 100px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxTablet} {
    width: 90%;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media${devices.maxLaptopL} {
    margin-bottom: 60px;
    width: auto;
  }
  @media${devices.maxTablet} {
    margin-bottom: 40px;
  }
`;

export const TitleFirstRowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleImage = styled.div`
  display: inline-block;
  width: 220px;
  height: 110px;
  border-radius: 110px;
  background-image: url('/assets/ocean.webp');
  margin-right: 20px;

  @media${devices.maxLaptopL} {
    margin-right: 16px;
  }

  @media${devices.maxTablet} {
    width: 110px;
    height: 46px;
    background-size: 100% 100%;
    margin-right: 10px;
  }
`;

export const Title = styled.text.attrs({
  className: 'strong-weight xhuge-text',
})`
  text-transform: uppercase;

  @media${devices.maxLaptopL} {
    font-size: 100px;
  }

  @media${devices.maxTablet} {
    font-size: 46px;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const Text = styled.div.attrs({
  className: 'medium-text semi-bold-weight',
})`
  opacity: 0.6;
  margin-left: auto;
  margin-right: 227px;
  max-width: 600px;

  @media${devices.maxLaptopL} {
    margin-right: 60px;
  }

  @media${devices.maxTablet} {
    font-size: 14px;
    opacity: 0.8;
    margin-right: 0;
  }
`;

export const Button = styled.button.attrs({
  className: 'xmedium-text strong-weight',
})`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f1f1f1;
  width: 224px;
  height: 62px;

  margin: 40px auto 0;

  &:hover {
    background-color: white;
    border: 1px solid #4439f3;
  }

  @media${devices.maxTablet} {
    margin: 30px auto 0;
  }
`;
