import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { CenterColumnType, JourneyTemplateProps, SideColumnType } from 'types';
import {
  Title,
  Wrapper,
  TitleWrapper,
  SideTitleElement,
  TitleElement,
  CenterTitleDivider,
  LabelsWrapper,
  Label,
  Divider,
  LabelsRow,
} from '../styles';
import { ItemWrapper } from './styles';

const MobileJourneyTemplate: FunctionComponent<JourneyTemplateProps> = (props) => {
  const mapToMobileComponents = useCallback(({ title, sideTitles, columns }: JourneyTemplateProps) => {
    return Object.keys(columns).map((columnKey, index) => {
      const column = columns[columnKey as 'left' | 'center' | 'right'];
      const isCenter = index === 1;

      const resolvedTitle = isCenter ? title : sideTitles[columnKey as 'left' | 'right'];
      const titleElement = isCenter ? (
        <TitleElement>
          {columns.center.separator && <CenterTitleDivider />}
          {columns.center.titleElement}
        </TitleElement>
      ) : (
        <SideTitleElement>{columns[columnKey as 'left' | 'right'].title}</SideTitleElement>
      );

      const centerBlocksList = (column as CenterColumnType).columns;
      const sideBlocksList = (column as SideColumnType).rowGroups;

      const rows = isCenter ? (
        <LabelsRow>
          {centerBlocksList.map((column, index) => (
            <Fragment key={index}>
              <LabelsWrapper>
                {column.rowGroups.map((rowGroup, index) => (
                  <Label key={index}>{rowGroup}</Label>
                ))}
              </LabelsWrapper>
            </Fragment>
          ))}
        </LabelsRow>
      ) : (
        <Fragment>
          <LabelsWrapper>
            {sideBlocksList.map((rowGroup, index) => (
              <Label key={index}>{rowGroup}</Label>
            ))}
          </LabelsWrapper>
        </Fragment>
      );

      return (
        <ItemWrapper key={columnKey}>
          <TitleWrapper $asColumn>
            <Title>{resolvedTitle}</Title>
            {titleElement}
          </TitleWrapper>
          <Divider />
          {rows}
          <Divider style={{ marginBottom: '40px' }} />
        </ItemWrapper>
      );
    });
  }, []);

  return <Wrapper>{mapToMobileComponents({ ...props })}</Wrapper>;
};

export default MobileJourneyTemplate;
