import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 59px;

  @media${devices.maxLaptopL} {
    gap: 0;
  }

  @media${devices.maxTablet} {
    flex-direction: column;
    gap: 40px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const RowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 60px;
  flex: 1;

  @media${devices.maxLaptopL} {
    margin-bottom: 40px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #6d65f6;
`;

export const RowWrapper = styled.div.attrs({ className: 'standard-text semi-bold-weight' })`
  width: 100%;
  text-align: center;
  padding: 20px;

  @media${devices.maxLaptopL} {
    font-size: 14px;
  }
`;
