import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { ColumnsType, JourneyTemplateProps } from 'types';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import MobileJourneyTemplate from 'common/components/JourneyTemplate/MobileTemplate';
import {
  Column,
  Divider,
  Label,
  LabelsRow,
  LabelsWrapper,
  Row,
  SideTitleElement,
  Title,
  TitleElement,
  TitleWrapper,
  Wrapper,
  CenterTitleDivider,
} from './styles';

const JourneyTemplate: FunctionComponent<JourneyTemplateProps> = ({ title, sideTitles, columns }) => {
  const { result: isTablet } = useMedia(devices.maxLaptopL);
  const { result: isMobile } = useMedia(devices.maxTablet);

  const doubleCenterColumn = columns.center.columns.length === 2;

  const mapToLabels = useCallback((labels: (string | React.JSX.Element)[], columnIndex?: number) => {
    return (
      <LabelsWrapper $biggerWidth={columnIndex !== 1 && columnIndex !== 2}>
        {labels.map((label, index) => (
          <Label key={index}>{label}</Label>
        ))}
      </LabelsWrapper>
    );
  }, []);

  const mapToColumns = useCallback(
    (columns: ColumnsType) => {
      const leftLength = columns.left.rowGroups.length;
      const centerLength = columns.center.columns[0].rowGroups.length;
      const rightLength = columns.right.rowGroups.length;

      const maxRows = Math.max(leftLength, centerLength, rightLength);

      const centerColumnRows = columns.center.columns.map((column) => column.rowGroups);
      const columnsArray = [columns.left.rowGroups, ...centerColumnRows, columns.right.rowGroups];

      const result = [];

      for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        const row = [];
        for (let columnIndex = 0; columnIndex < columnsArray.length; columnIndex++) {
          const columnIndexToBePassed = doubleCenterColumn ? columnIndex : undefined;
          const column = mapToLabels(columnsArray[columnIndex][rowIndex], columnIndexToBePassed);
          row.push(column);
        }
        result.push(
          <Fragment>
            <Divider />
            <LabelsRow>{row}</LabelsRow>
            {rowIndex === maxRows - 1 && <Divider />}
          </Fragment>,
        );
      }

      return result;
    },
    [mapToLabels, doubleCenterColumn],
  );

  return (
    <>
      {isMobile ? (
        <MobileJourneyTemplate title={title} sideTitles={sideTitles} columns={columns} />
      ) : (
        <Wrapper>
          <Column>
            <Row>
              <TitleWrapper $asColumn={isTablet}>
                <Title $vertical={!isTablet} $verticalInversely={!isTablet}>
                  {sideTitles.left}
                </Title>
                <SideTitleElement>{columns.left.title}</SideTitleElement>
              </TitleWrapper>
              <TitleWrapper $asColumn>
                <Title>{title}</Title>
                <TitleElement>
                  {columns.center.separator && <CenterTitleDivider />}
                  {columns.center.titleElement}
                </TitleElement>
              </TitleWrapper>
              <TitleWrapper $asColumn={isTablet} $rewert={isTablet}>
                <SideTitleElement>{columns.right.title}</SideTitleElement>
                <Title $vertical={!isTablet}>{sideTitles.right}</Title>
              </TitleWrapper>
            </Row>
            {mapToColumns(columns)}
          </Column>
        </Wrapper>
      )}
    </>
  );
};

export default JourneyTemplate;
