import styled from 'styled-components';

export const SwiperWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide {
    display: flex;
    justify-content: center;
  }

  & .swiper-button-prev:after {
    display: none;
  }

  & .swiper-button-next:after {
    display: none;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: row-reverse;
  padding-right: 20px;
`;

export const SwiperButton = styled.button.attrs({
  className: 'secondary-background primary-color',
})<{ $inverted?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  top: 0;
  margin-top: 0;
  right: 0;
  left: 0;

  ${({ $inverted }) =>
    $inverted &&
    `
        background-color: #fff;
        color: #4439F3 !important;
    `}
`;
