import styled from 'styled-components';
import { devices } from 'common/const';

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media${devices.maxLaptopL} {
    justify-content: center;
  }

  @media${devices.maxTablet} {
    width: 90%;
  }
`;

export const MainImageWrapper = styled.div.attrs({
  className: 'secondary-background',
})<{ $radius: string }>`
  flex: 1;
  border-radius: ${({ $radius }) => $radius};
  width: 447px;
  height: 447px;

  max-width: 447px;
  max-height: 447px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media${devices.maxLaptopL} {
    width: 51px;
    height: 51px;

    max-width: 51px;
    max-height: 51px;
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow: hidden;
  max-width: 679px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const Title = styled.div.attrs({
  className: 'large-text strong-weight',
})`
  text-transform: uppercase;
  margin-bottom: 20px;

  @media${devices.maxLaptopL} {
    margin-bottom: 0;
  }

  @media${devices.maxTablet} {
    font-size: 24px;
  }
`;

export const Description = styled.div.attrs({ className: 'standard-text semi-bold-weight' })`
  opacity: 0.8;
  margin-bottom: 40px;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const Image = styled.img`
  width: 213px;
  height: 120px;
  border-radius: 20px;
`;
