import React, { memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { Container, Wrapper, Item, BlankItem } from './styles';

const Ideas: React.FunctionComponent = () => {
  const items = [
    'Tech Journey',
    'Collaborative Excellence',
    'The Future is now',
    'Dream Crafting',
    'From Brainstorms to Breakthroughs',
    'Behind Tech',
    'Synchronised for Success',
    'Shared Vision, Shared Victories',
  ];

  const spaceAfterItem = [0, 1, 4, 5];
  const spaceBeforeItem = [2, 3, 6, 7];

  // const groupedItems = items.reduce((acc, item, index) => {
  //   const group = [];
  //
  //   if (index === 0 || index === 1) (acc[0] ?? []).push(item);
  //   if (index === 2 || index === 3) (acc[1] ?? []).push(item);
  //   if (index === 4 || index === 5) (acc[2] ?? []).push(item);
  //
  //   return [...acc, group];
  // }, [] as string[][]);
  //
  // console.log(groupedItems);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          {items.map((item, index) => (
            <>
              {spaceBeforeItem.includes(index) && <BlankItem />}
              <Item key={item}>{item}</Item>
              {spaceAfterItem.includes(index) && <BlankItem />}
            </>
          ))}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Ideas);
