import { CSSProperties, FunctionComponent, memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateToCustomerForm } from 'common/utils';
import { Wrapper } from './styles';

type Props = {
  className?: string;
  style?: CSSProperties;
};

const LetsTalk: FunctionComponent<Props> = ({ className, style, ...rest }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [hovered, setHovered] = useState(false);

  return (
    <Wrapper
      $hovered={hovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => navigateToCustomerForm(navigate, pathname)}
      className={className}
      style={style}
      {...rest}
    >
      {hovered ? 'You’ll be talking with our technology experts.' : 'LET’S TALK'}
    </Wrapper>
  );
};

export default memo(LetsTalk);
