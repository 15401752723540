import { FunctionComponent, memo } from 'react';
import { Wrapper, Number, Zero } from './styles';

const NotFoundLogo: FunctionComponent = () => {
  return (
    <Wrapper>
      <Number>4</Number>
      <Zero />
      <Number>Four</Number>
    </Wrapper>
  );
};

export default memo(NotFoundLogo);
