import React, { Fragment, FunctionComponent, JSX } from 'react';
import { Wrapper, Column, Divider, TitleWrapper, RowWrapper, RowsWrapper } from './styles';

type Props = {
  columns: { title: React.JSX.Element; rows: string[]; rowElement?: JSX.Element; hideDivider?: boolean }[];
};

const TableTemplate: FunctionComponent<Props> = ({ columns }) => {
  return (
    <Wrapper>
      {columns.map((column, index) => (
        <Column key={index}>
          <TitleWrapper>{column.title}</TitleWrapper>

          <RowsWrapper>
            {!column?.hideDivider && <Divider />}
            {column.rows.map((row, index) => (
              <Fragment key={`${row}_${index}`}>
                <RowWrapper>{row}</RowWrapper>
                <Divider />
              </Fragment>
            ))}
            {column.rowElement && column.rowElement}
          </RowsWrapper>
        </Column>
      ))}
    </Wrapper>
  );
};

export default TableTemplate;
