import { CustomerFormDataType } from 'types';
import { DESVERSE_API_URL } from 'common/const';

export const postCustomerForm = async (props: {
  body: CustomerFormDataType;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { body, onSuccess, onError } = props;
  const mode = process.env.NODE_ENV.toLowerCase();

  fetch(`${DESVERSE_API_URL}/icecode/${mode}/submit`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(({ success }: { success: boolean }) => {
      if (success) onSuccess();
      else onError();
    })
    .catch(() => onError());
};

export const uploadFile = async (props: { file: any }) => {
  const { file } = props;
  return fetch(`${DESVERSE_API_URL}/upload`, { method: 'POST', body: file }).then((res) => res.json());
};
