import styled from 'styled-components';
import { devices } from 'common/const';
import { Title } from 'common/components/Welcome/styles';

export const Wrapper = styled.div.attrs({ className: 'primary-inverted-background primary-inverted-color' })`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  @media${devices.maxTablet} {
    height: auto;
    padding: 150px 0;
    width: 90%;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media${devices.maxLaptopL} {
    width: 95%;
    justify-content: space-between;
  }

  @media${devices.maxTablet} {
    width: 100%;
    gap: 6px;
  }
`;

export const Text = styled(Title)`
  @media${devices.maxTablet} {
    width: 100%;
  }
`;
