import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-bottom: 176px;

  @media${devices.maxLaptopL} {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 160px;
  }

  @media${devices.maxTablet} {
    gap: 20px;
    margin-bottom: 80px;
  }
`;

export const Item = styled.div.attrs({
  className: 'secondary-background',
})`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 30px;

  width: 447px;
  height: 270px;

  @media${devices.maxLaptopL} {
    width: 454px;
  }

  @media${devices.maxTablet} {
    padding: 20px;
    width: 100%;
    height: 208px;
  }
`;

export const Title = styled.div.attrs({
  className: 'semi-large-text strong-weight',
})`
  text-transform: uppercase;

  @media${devices.maxTablet} {
    font-size: 28px;
  }
`;

export const Text = styled.div.attrs({
  className: 'semi-bold-weight medium-text',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > text {
    opacity: 0.8;
  }

  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;
