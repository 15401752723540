export const dimensions = {
  desktopContentWidth: 1440,
  desktopPaddingTop: 64,
};

export const screenSize = {
  mobileS: 320,
  maxMobileS: 374,
  mobileM: 375,
  mobileL: 430,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const devices = {
  mobileS: `(min-width: ${screenSize.mobileS}px)`,
  mobileM: `(min-width: ${screenSize.mobileM}px)`,
  mobileL: `(min-width: ${screenSize.mobileL}px)`,
  tablet: `(min-width: ${screenSize.tablet + 1}px)`,
  laptop: `(min-width: ${screenSize.laptop}px)`,
  laptopL: `(min-width: ${screenSize.laptopL}px)`,
  desktop: `(min-width: ${screenSize.desktop}px)`,
  desktopL: `(min-width: ${screenSize.desktop}px)`,
  maxMobileS: `(max-width: ${screenSize.maxMobileS}px)`,
  maxMobileM: `(max-width: ${screenSize.mobileM}px)`,
  maxMobileL: `(max-width: ${screenSize.mobileL}px)`,
  maxTablet: `(max-width: ${screenSize.tablet}px)`,
  maxLaptop: `(max-width: ${screenSize.laptop}px)`,
  maxLaptopL: `(max-width: ${screenSize.laptopL}px)`,
  maxDesktop: `(max-width: ${screenSize.desktop}px)`,
  maxDesktopL: `(max-width: ${screenSize.desktop}px)`,
};

export const CUSTOMER_FORM_ID = 'call';

export const SUPPORT_EMAIL = 'sales@icecode.co';
export const REGISTRATION_COUNTRY = 'UNITED KINGDOM';
export const REGISTRATION_ADDRESS = '27, Old Gloucester Street, LONDON, WC1N 3AX';
export const CURRENT_DATE = new Date().getFullYear();

export const INSTAGRAM = 'https://instagram.com/ice.c0de?igshid=NzZlODBkYWE4Ng==';
export const LINKED_IN = 'https://www.linkedin.com/company/icecode-co';

export const MAIN_VIDEO = 'https://player.vimeo.com/883571878';

export const AFFPRO_VIDEO = 'https://player.vimeo.com/video/869052102';
export const CRYPTO_WALLET_VIDEO = 'https://player.vimeo.com/video/882125326';
export const MODERNACASA_VIDEO = 'https://player.vimeo.com/video/869218147';
export const RICO_VIDEO = 'https://player.vimeo.com/video/869189045';
export const DESVERSE_VIDEO = 'https://player.vimeo.com/video/875627130';

export const DESVERSE_URL = 'https://desverse.co';
export const DESVERSE_DRIBBLE = 'https://dribbble.com/DESVERSE';
export const DESVERSE_BEHANCE = 'https://www.behance.net/desverse';

export const DESVERSE_API_URL = 'https://api.desverse.co/api';

export const FETCH_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};
