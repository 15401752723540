import styled from 'styled-components';
import { Title } from 'common/components/SquareWelcome/styles';
import { devices } from 'common/const';

export const CenterTitleWrapper = styled.div`
  display: flex;
  position: relative;
  width: 690px;
  height: 690px;
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CenterTitle = styled.div`
  display: flex;
  position: absolute;

  & > div {
    opacity: 1;
  }
`;

export const TitleVertical = styled(Title)`
  writing-mode: vertical-rl;
  text-orientation: mixed;
`;

export const BorderedWrapper = styled.div.attrs({
  className: 'primary-inverted-color',
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > div {
    opacity: 1;
  }

  @media${devices.maxTablet} {
    position: relative;
    transform: none;
    top: unset;
    left: unset;
  }
`;

export const Text = styled.text.attrs({
  className: 'standard-text',
})`
  opacity: 0.8;
  text-align: center;
  max-width: 386px;
`;
