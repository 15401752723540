import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 250px;

  @media${devices.maxLaptopL} {
    padding-bottom: 200px;
  }

  @media${devices.maxTablet} {
    padding-bottom: 80px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 40px;
  overflow-x: auto;
  overflow-y: hidden;

  @media${devices.maxLaptopL} {
    gap: 16px;
    width: 90%;
  }
`;
