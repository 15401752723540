import React, { memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import MobileSwiper from 'common/components/MobileSwiper';
import { SwiperSlide } from 'swiper/react';
import { CasesWrapper, Container, SubTitle, Title, Wrapper } from './styles';
import { cases } from './const';
import CaseItem from './CaseItem';

const Cases = () => {
  const { result: isTablet } = useMedia(devices.maxTablet);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>Our cases</Title>
          <SubTitle>
            We love working with many fantastic companies and truly believe that we were created with them for
            each other
          </SubTitle>
          <CasesWrapper>
            {isTablet ? (
              <MobileSwiper
                items={cases.map((item) => (
                  <SwiperSlide key={item.title}>
                    <CaseItem {...item} />
                  </SwiperSlide>
                ))}
              />
            ) : (
              cases.map((item) => <CaseItem key={item.title} {...item} />)
            )}
          </CasesWrapper>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Cases);
