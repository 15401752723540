import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { FETCH_STATUS } from 'common/const';
import * as api from 'service/api';
import { convertToBase64 } from 'common/utils';

export const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
export const FILE_SIZE_LIMIT_IN_MB = FILE_SIZE_LIMIT / (1024 * 1024);
export const ALLOWED_FILE_EXTENSIONS = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt'];

export const useCustomerForm = () => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [budget, setBudget] = useState('');
  const [details, setDetails] = useState('');
  const [file, setFile] = useState<any>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileName, setFileName] = useState<string>();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const [status, setStatus] = useState<string | null>(null);

  const validateFile = (file: File): boolean => {
    const { name, size } = file;

    if (size > FILE_SIZE_LIMIT) {
      alert('File size is too large');
      return false;
    }

    const fileExtension = name.substring(name.lastIndexOf('.'));
    if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      alert('File extension type not supported');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await api.postCustomerForm({
      body: {
        name,
        email,
        phoneNumber,
        company,
        budget,
        details,
        file: fileUrl,
        date: new Date().toString(),
      },
      onSuccess: () => setStatus(FETCH_STATUS.SUCCESS),
      onError: () => setStatus(FETCH_STATUS.ERROR),
    });
  };

  const invalidateForm = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setCompany('');
    setBudget('');
    setDetails('');
    removeFile();

    formRef.current?.reset();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      if (!validateFile(file)) return;
      setIsFileUploading(true);
      setFileName(file.name);

      const base64 = await convertToBase64(file);

      setFile(base64);
    }
  };

  const removeFile = () => {
    setFile('');
    setFileName(undefined);
  };

  useEffect(() => {
    if (status === FETCH_STATUS.SUCCESS) {
      alert('Your request has been sent successfully');
      invalidateForm();
    }

    if (status === FETCH_STATUS.ERROR) {
      alert('Something went wrong. Please try again later');
    }
  }, [status]);

  useEffect(() => {
    if (file) {
      console.log(file);
      api
        .uploadFile({
          file,
        })
        .then((response: { url: string }) => {
          setFileUrl(response.url);
        })
        .finally(() => setIsFileUploading(false));
    }
  }, [file]);

  return {
    removeFile,
    handleSubmit,
    hiddenFileInput,
    formRef,
    isFileUploading,
    fileName,
    isFileUploadingFailed: fileName && !fileUrl,
    data: { name, email, phoneNumber, company, budget, details, file },
    onNameChange: (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    onEmailChange: (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    onPhoneNumberChange: (value: string) => {
      setPhoneNumber(value);
    },
    onCompanyChange: (e: ChangeEvent<HTMLInputElement>) => {
      setCompany(e.target.value);
    },
    onBudgetChange: (e: ChangeEvent<HTMLInputElement>) => {
      setBudget(e.target.value);
    },
    onDetailsChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
      setDetails(e.target.value);
    },
    onFileChange,
  };
};
