import { FunctionComponent, memo, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Wrapper } from './styles';

type Props = {
  url: string;
  width: string;
  height: string;
  placeholder: string;
  borderRadius?: string;
  aspectRatio?: string;
  iframeFullWidth?: boolean;
};

const VideoComponent: FunctionComponent<Props> = ({
  url,
  width,
  height,
  borderRadius,
  placeholder,
  aspectRatio,
  iframeFullWidth,
}) => {
  const [playStarted, setPlayStarted] = useState(false);

  return (
    <Wrapper
      $aspectRatio={aspectRatio}
      $playStarted={playStarted}
      $borderRadius={borderRadius ?? '0'}
      $iframeFullWidth={iframeFullWidth}
      style={{ width, height }}
    >
      <img
        height={height}
        width={width}
        style={{ borderRadius, display: playStarted ? 'none' : 'block', objectFit: 'cover' }}
        src={placeholder}
        alt={placeholder}
      />
      <Vimeo
        loop
        autoplay
        autopause={false}
        controls={false}
        volume={0}
        video={url}
        width={width}
        height={height}
        onPlay={() => setPlayStarted(true)}
      />
    </Wrapper>
  );
};

export default memo(VideoComponent);
