import React from 'react';
import { Swiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ArrowIcon from 'common/components/ArrowIcon';
import { NavigationWrapper, SwiperButton, SwiperWrapper } from './styles';

type Props = {
  items: React.JSX.Element[];
  invertedButtons?: boolean;
};

const MobileSwiper: React.FunctionComponent<Props> = ({ items, invertedButtons }) => {
  const navigation = {
    nextEl: `.swiper-button-next `,
    prevEl: `.swiper-button-prev `,
  };

  return (
    <SwiperWrapper>
      <Swiper navigation={navigation} modules={[Navigation]}>
        {items}
      </Swiper>
      <NavigationWrapper>
        <SwiperButton className="swiper-button-next" $inverted={invertedButtons}>
          <ArrowIcon color={invertedButtons ? '#2F23F2' : '#FFFFFF'} />
        </SwiperButton>
        <SwiperButton className="swiper-button-prev" $inverted={invertedButtons}>
          <ArrowIcon color={invertedButtons ? '#2F23F2' : '#FFFFFF'} orientation="left" />
        </SwiperButton>
      </NavigationWrapper>
    </SwiperWrapper>
  );
};

export default MobileSwiper;
