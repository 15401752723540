import React, { FunctionComponent } from 'react';
import SquareWelcome from 'common/components/SquareWelcome';
import Faq from 'common/components/Faq';
import Execution from 'common/components/Execution';
import Technologies from 'common/components/Technologies';
import Collaboration from 'common/components/Collaboration';
import { Wrapper } from './styles';

const MainSpeciality: FunctionComponent = () => {
  return (
    <Wrapper>
      <SquareWelcome />
      <Execution />
      <Technologies />
      <Collaboration />
      <Faq />
    </Wrapper>
  );
};

export default MainSpeciality;
