import React, { FunctionComponent, memo } from 'react';
import Lottie from 'lottie-react';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import VideoComponent from 'common/components/VideoComponent';
import { isVideoSource } from 'common/utils';
import {
  Data,
  Description,
  Image,
  ImagesWrapper,
  MainImageWrapper,
  Row,
  Title,
  TitleWrapper,
} from './styles';

type Props = {
  title: string;
  description: string;
  mainImage: string;
  imageWrapperRadius: string;
  lottie: any;
  cases?: { video: string; placeholder: string }[];
};

const AboutItem: FunctionComponent<Props> = ({ title, cases, description, lottie, imageWrapperRadius }) => {
  const { isDesktop, result: isLaptopL } = useMedia(devices.maxLaptopL);

  const mapToCases = (cases: { video: string; placeholder: string }[]) => {
    return cases.map(({ video, placeholder }) => {
      if (isVideoSource(video)) {
        return (
          <VideoComponent
            url={video}
            placeholder={placeholder}
            width="213px"
            height="120px"
            borderRadius="20px"
          />
        );
      }
      return <Image key={placeholder} src={placeholder} />;
    });
  };

  return (
    <Row>
      {isDesktop && (
        <MainImageWrapper $radius={imageWrapperRadius}>
          <Lottie animationData={lottie} />
        </MainImageWrapper>
      )}
      <Data>
        <TitleWrapper>
          {isLaptopL && (
            <MainImageWrapper $radius={imageWrapperRadius}>
              <Lottie animationData={lottie} />
            </MainImageWrapper>
          )}
          <Title>{title}</Title>
        </TitleWrapper>

        <Description>{description}</Description>
        {cases && <ImagesWrapper>{mapToCases(cases)}</ImagesWrapper>}
      </Data>
    </Row>
  );
};

export default memo(AboutItem);
