import { FunctionComponent, useMemo } from 'react';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import { Title } from '../styles';
import {
  CenterTitleWrapper,
  CenterTitle,
  TitleVertical,
  BorderedWrapper,
  Text,
  MobileWrapper,
} from './styles';

const CenterBlock: FunctionComponent = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);

  const centeredText = useMemo(
    () => (
      <BorderedWrapper>
        <Title>APP</Title>
        <Text>
          In our Web Development department, we work to create high-quality, fast, and responsive websites
          tailored to individual client needs. Our portfolio includes corporate websites, e-commerce
          platforms, landing pages, and various web projects. Our specialists are equipped with the latest
          technologies and knowledge to bring the boldest ideas to life.
        </Text>
      </BorderedWrapper>
    ),
    [],
  );

  return (
    <>
      {isMobile ? (
        <MobileWrapper>{centeredText}</MobileWrapper>
      ) : (
        <CenterTitleWrapper>
          <CenterTitle style={{ alignItems: 'flex-end', bottom: 0 }}>
            <TitleVertical className="primary-inverted-color">Development</TitleVertical>
            <Title style={{ color: '#4439F3' }}>Development</Title>
          </CenterTitle>
          {centeredText}
          <CenterTitle>
            <Title style={{ color: '#4439F3' }}>Development</Title>
            <TitleVertical className="primary-inverted-color">Development</TitleVertical>
          </CenterTitle>
        </CenterTitleWrapper>
      )}
    </>
  );
};

export default CenterBlock;
