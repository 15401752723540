import styled from 'styled-components';

export const Wrapper = styled.div<{
  $borderRadius: string;
  $playStarted: boolean;
  $aspectRatio?: string;
  $iframeFullWidth?: boolean;
}>`
  display: flex;
  align-items: center;

  & iframe {
    ${({ $iframeFullWidth }) =>
      $iframeFullWidth &&
      `
        width: 100%;
        height: 100%;
    `}

    border-radius: ${({ $borderRadius }) => $borderRadius};

    ${({ $playStarted }) => !$playStarted && 'display: none;'}
    ${({ $aspectRatio }) => $aspectRatio && `aspect-ratio: ${$aspectRatio};`}
  }
`;
