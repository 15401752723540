import styled from 'styled-components';
import { devices } from 'common/const';

export const IconWrapper = styled.div<{ $inverted: boolean }>`
  border-radius: 10px;
  border: ${({ $inverted }) => ($inverted ? ' 1px solid #2f23f2' : ' 1px solid #fff')};

  background-color: ${({ $inverted }) => ($inverted ? '#fff' : '#4439F3')};

  & > div {
    width: 50px !important;
    height: 32px !important;
  }

  & > div > div {
    height: 1px !important;
    left: 10px !important;
    width: 30px !important;

    background: ${({ $inverted }) => ($inverted ? ' #4439F3' : '#fff')} !important;
  }

  & > div > div:nth-child(1) {
    top: 9px !important;
  }

  & > div > div:nth-child(2) {
    top: auto !important;
    bottom: 9px !important;
  }
`;

export const MenuWrapper = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  height: calc(100vh - 66px);
  position: absolute;
  z-index: 0;
  opacity: 0;
  top: -120%;
  left: 0;
  transition: all 0.3s;
  background-color: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ $isOpen }) =>
    $isOpen
      ? `
    z-index: 4;
    top: 66px;
    opacity: 1;
    `
      : `
    z-index: -1;
    top: -3000%;
    opacity: 0;
    `}
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex: 2;
  padding: 20px;

  @media${devices.maxTablet} {
    gap: 20px;
  }
`;

export const Link = styled.a.attrs({ className: 'xlarge-text strong-weight' })`
  color: #2f23f2 !important;
  text-transform: uppercase;
  text-align: center;

  @media${devices.maxTablet} {
    font-size: 42px;
  }
`;

export const CallToAction = styled.button.attrs({
  className: 'standard-text secondary-background primary-color',
})`
  display: none;

  @media${devices.maxTablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #fff;
    margin-top: 40px;
  }
`;

export const FooterWrapper = styled.div.attrs({ className: 'secondary-background' })`
  display: flex;
  width: 100%;
  padding: 30px;
  align-items: center;
  justify-content: center;
  gap: 60px;
  flex: 1;
  max-height: 84px;
  flex-wrap: wrap;

  @media${devices.maxTablet} {
    max-height: 80px;
    column-gap: 60px;
    row-gap: 12px;
    padding: 10px;
  }
`;

export const FooterLink = styled.a.attrs({ className: 'medium-text primary-color semi-bold-weight' })`
  opacity: 0.8;
  cursor: pointer;
`;
