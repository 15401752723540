import React, { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import JourneyTemplate from 'common/components/JourneyTemplate';
import { JourneyTemplateProps } from 'types';
import DesktopSwiper from 'common/components/DesktopSwiper';
import { SwiperSlide } from 'swiper/react';
import { feedbacks } from 'common/components/Feedbacks/const';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import MobileSwiper from 'common/components/MobileSwiper';
import {
  CenterTitleWrapper,
  Container,
  ItemBodyText,
  ItemHeader,
  ItemImage,
  ItemName,
  ItemPosition,
  ItemsWrapper,
  LabelWithBorder,
  LabelWithOpacity,
  LetsTalkButton,
  Wrapper,
} from './styles';

const Execution: FunctionComponent = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);

  const executionTemplateConfig = {
    title: 'Execution',
    sideTitles: { left: 'Strategy', right: 'Market' },
    columns: {
      left: {
        title: 'Product discovery',
        rowGroups: [
          ['3-8 weeks'],
          ['Visual proof of concept, cost estimation and delivery plan'],
          ['Project manager', 'UX/UI designer', 'System architect', 'Solution architect'],
        ],
      },
      center: {
        separator: true,
        titleElement: (
          <CenterTitleWrapper>
            <text>Product design</text>
            <text>Software engineering</text>
          </CenterTitleWrapper>
        ),
        columns: [
          {
            rowGroups: [
              ['8-12 weeks'],
              ['Product design ready for implementation'],
              ['Project manager', 'UX/UI designer'],
            ],
          },
          {
            rowGroups: [
              ['12-24 weeks'],
              [<LabelWithBorder>MVP</LabelWithBorder>],
              ['Project manager', 'Software engineers', 'QA engineer', 'Solution architect'],
            ],
          },
        ],
      },
      right: {
        title: 'Continual product improvements',
        rowGroups: [
          [<LabelWithOpacity>as needed</LabelWithOpacity>],
          ['New features'],
          ['Project manager', 'Software engineers', 'QA engineer'],
        ],
      },
    },
  } as JourneyTemplateProps;

  const swiperItems = feedbacks.map((item) => (
    <SwiperSlide key={item.name}>
      <ItemsWrapper>
        <ItemHeader>
          <ItemImage src={item.image} alt={item.image} />
          <div>
            <ItemName>{item.name}</ItemName>
            <ItemPosition>{item.position}</ItemPosition>
          </div>
        </ItemHeader>
        <ItemBodyText>{item.text}</ItemBodyText>
      </ItemsWrapper>
    </SwiperSlide>
  ));

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <JourneyTemplate {...executionTemplateConfig} />
          <LetsTalkButton/>
          {isMobile ? (
            <MobileSwiper items={swiperItems} invertedButtons />
          ) : (
            <DesktopSwiper items={swiperItems} />
          )}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Execution);
