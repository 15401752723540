import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { paths } from 'common/const/urls';
import {
  Container,
  Wrapper,
  TitleWrapper,
  TitleImage,
  Title,
  TitleFirstRowWrapper,
  TextWrapper,
  Text,
  Button,
} from './styles';

const Ocean: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <TitleWrapper>
            <TitleFirstRowWrapper>
              <TitleImage />
              <Title>OCEAN OF</Title>
            </TitleFirstRowWrapper>
            <Title>TECH TALENTS</Title>
          </TitleWrapper>
          <TextWrapper>
            <Text>
              Dive deep into our vast ocean of technological expertise. At IceCode, we pride ourselves on
              housing a diverse pool of skilled developers and tech specialists ready to tackle any challenge.
              From visionary startups to established enterprises, our team offers a unique blend of
              experience, innovation, and dedication. Whether you're looking to craft the next big app, design
              an intuitive user interface, or redefine your digital presence, our talent pool is vast,
              versatile, and vibrant. Partner with us and sail smoothly through the ever-evolving tech waters,
              anchored by the industry's top talent.
            </Text>
            <Button onClick={() => navigate(paths.CUSTOM)}>GO TO CUSTOM page</Button>
          </TextWrapper>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Ocean);
