import React, { memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import FeedbackItem from 'common/components/Feedbacks/FeedbackItem';
import MobileSwiper from 'common/components/MobileSwiper';
import { SwiperSlide } from 'swiper/react';
import { Container, Wrapper } from './styles';
import { feedbacks } from './const';

const Feedbacks: React.FunctionComponent = () => {
  const { result: isTablet } = useMedia(devices.maxTablet);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          {isTablet ? (
            <MobileSwiper
              items={feedbacks.map((item) => (
                <SwiperSlide key={item.name}>
                  <FeedbackItem
                    image={item.image}
                    name={item.name}
                    position={item.position}
                    text={item.text}
                  />
                </SwiperSlide>
              ))}
            />
          ) : (
            feedbacks.map((item) => (
              <FeedbackItem
                key={item.name}
                image={item.image}
                name={item.name}
                position={item.position}
                text={item.text}
              />
            ))
          )}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Feedbacks);
