import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 0;

  @media${devices.maxLaptopL} {
    padding: 40px 0;
  }
  @media${devices.maxTablet} {
    padding: 40px 0 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
  @media${devices.maxTablet} {
    gap: 40px;
  }
`;

export const LogoLine = styled.div``;

export const MenuLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  gap: 60px;

  @media${devices.maxLaptopL} {
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }

  @media${devices.maxTablet} {
    flex-direction: column;
    gap: 50px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const Title = styled.div.attrs({ className: 'medium-text strong-weight' })`
  text-transform: uppercase;
  margin-bottom: 6px;

  @media${devices.maxTablet} {
    margin-bottom: 0;
  }
`;

export const Label = styled.a.attrs({ className: 'semi-bold-weight medium-text' })`
  opacity: 0.8;
  margin-bottom: 6px;

  @media${devices.maxTablet} {
    margin-bottom: 0;
  }
`;

export const PartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const PartnersLine = styled.div`
  display: flex;
  gap: 10px;

  @media${devices.maxTablet} {
    flex-direction: column;
  }
`;

export const PartnersItem = styled.a.attrs({ className: 'primary-inverted-background', target: '_blank' })`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  width: 146px;
  height: 63px;

  cursor: pointer;

  @media${devices.maxTablet} {
    width: 100%;
  }
`;

export const PolicyLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media${devices.maxTablet} {
    flex-direction: column;
    gap: 40px;
  }
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  align-items: end;
`;
