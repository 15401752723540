import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  height: 234px;
  display: flex;
  gap: 20px;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide {
    display: flex;
    justify-content: center;
  }

  & .swiper-button-prev:after {
    display: none;
  }

  & .swiper-button-next:after {
    display: none;
  }

  @media${devices.maxLaptopL} {
    gap: 10px;
  }
`;

export const NavigationButton = styled.button`
  height: 100%;
  width: 64px;
  border-radius: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  margin-top: 0;
  right: 0;
  left: 0;
  position: relative;
  cursor: pointer;
`;
