import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;

  @media${devices.maxLaptopL} {
    margin-bottom: 100px;
  }
  @media${devices.maxTablet} {
    margin-bottom: 60px;
  }
`;
