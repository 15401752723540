import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div<{ $isDragging: boolean }>`
  z-index: ${({ $isDragging }) => ($isDragging ? 99 : 1)};
  position: absolute;
`;

export const Image = styled.img<{
  $desktop: { width: string; height: string };
  $tablet: { width: string; height: string };
  $mobile: { width: string; height: string };
  $transform?: string;
}>`
  cursor: grab;

  border-radius: 20px;
  border: 10px solid #fff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.15);
  width: ${({ $desktop }) => $desktop.width};
  height: ${({ $desktop }) => $desktop.height};

  @media${devices.maxLaptopL} {
    border: 8px solid #fff;
    width: ${({ $tablet }) => $tablet.width};
    height: ${({ $tablet }) => $tablet.height};
  }

  @media${devices.maxTablet} {
    border: 4px solid #fff;
    border-radius: 10px;
    width: ${({ $mobile }) => $mobile.width};
    height: ${({ $mobile }) => $mobile.height};
  }
`;
