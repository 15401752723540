import { useNavigate } from 'react-router-dom';
import { CUSTOMER_FORM_ID } from 'common/const';

export const isVideoSource = (source: string) => {
  return source.includes('player.vimeo.com');
};

export const navigateToCustomerForm = (navigate: ReturnType<typeof useNavigate>, currentPath: string) => {
  navigate(`${currentPath}#${CUSTOMER_FORM_ID}`);
};

export const convertToBase64 = (file: File) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
  });
};
