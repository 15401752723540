import { Fragment, FunctionComponent, memo, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement: FunctionComponent = () => {
  const location = useLocation();

  const hashElement = useMemo(() => {
    const { hash } = location;
    const removeHashCharacter = (str: string) => str.slice(1);

    if (hash) return document.getElementById(removeHashCharacter(hash));
    else return null;
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [hashElement]);

  return <Fragment />;
};

export default memo(ScrollToHashElement);
