import { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import {
  Container,
  Wrapper,
  ArticleWrapper,
  Subtitle,
  TitleWrapper,
  Text,
  TitlePart,
  TitleWordsGroup,
  TitlePartImage,
  Row,
  LastImages,
  ImageWrapper,
  Meme,
} from './styles';

// TODO refactor this component
const Gazette: FunctionComponent = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);
  const { result: isLaptop } = useMedia(devices.maxLaptopL);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <ArticleWrapper
            $row={!isLaptop}
            $customStyles={`
              gap: 137px;
              
              @media${devices.maxLaptopL} {
                flex-direction: column-reverse;
                gap: 40px;
              }
            `}
          >
            <TitleWrapper
              $row={!isMobile}
              $customStyles={`
                    @media${devices.maxLaptopL} {
                      justify-content: flex-end;
                    }
                    
                    @media${devices.maxTablet} {
                      justify-content: flex-start;
                    }
                `}
            >
              <Subtitle $vertical={!isMobile}>Dedicated Team</Subtitle>
              <Text
                $customStyles={`
                  max-width: 329px;
                  
                  @media${devices.maxTablet} {
                    max-width: 100%;
                  }
                `}
              >
                Dedicated Team Our "Dedicated Team" service provides your business with experienced and highly
                skilled specialists, who work exclusively on your project. This is an ideal solution for
                businesses looking to expand their development team without the additional costs and efforts
                associated with hiring, training, and managing staff. Our teams adapt to your business
                processes and corporate culture, offering you flexibility and access to the latest
                technological skills, all while ensuring your project's success.
              </Text>
            </TitleWrapper>
            <TitleWordsGroup>
              <Row>
                <TitlePart>Dedica</TitlePart>
                <TitlePartImage />
              </Row>
              <TitlePart>ted Team</TitlePart>
            </TitleWordsGroup>
            {isLaptop && (
              <Meme
                src="/assets/memes/junior.webp"
                $customStyles={`
                bottom: 100px;
                left: 43px;
                transform: rotate(-8deg);
                width: 282px;
                height: 202px;
              `}
              />
            )}
          </ArticleWrapper>
          <ArticleWrapper>
            <Meme
              src="/assets/memes/robots.webp"
              $customStyles={`
                top: ${isLaptop ? '0' : '-130px'};
                right: 0;
                transform: rotate(12deg);
                ${isLaptop && 'width: 282px; height: 202px;'}
              `}
            />
            {!isLaptop && (
              <Meme
                src="/assets/memes/junior.webp"
                $customStyles={`
                bottom: -150px;
                left: 43px;
                transform: rotate(-8deg);
                width: 462px;
                height: 330px;
              `}
              />
            )}
            <TitleWordsGroup
              $customStyles={`
                margin-bottom: 60px;
                
                @media${devices.maxLaptopL} {
                  align-items: flex-start !important;
                }
              `}
            >
              <TitlePart>Flexible</TitlePart>
              <TitlePart>conditions</TitlePart>
              <TitlePart>for StartUps</TitlePart>
            </TitleWordsGroup>
            <TitleWrapper
              $customStyles={`
                    align-items: flex-end;
                `}
            >
              <Subtitle>Flexible Conditions for StartUps</Subtitle>
              <Text
                $customStyles={`
                  max-width: 564px;
                  
                  @media${devices.maxLaptopL} {
                    max-width: 100%;
                  }
                `}
              >
                We are committed to supporting startups by providing flexible conditions that accommodate the
                unique challenges and opportunities they face. Understanding that startups operate under tight
                constraints, our adaptable services, pricing models, and development strategies are
                specifically designed to enable innovation and growth while navigating the initial phases of
                business development. Let’s bring your innovative ideas to life together while maintaining
                financial and operational feasibility.
              </Text>
            </TitleWrapper>
          </ArticleWrapper>
          <ArticleWrapper
            $row={!isLaptop}
            $customStyles={`
              justify-content: space-between;
              align-items: flex-end;
              
              @media${devices.maxLaptopL} {
                 flex-direction: column-reverse;
                 align-items: flex-start;
                 gap: 40px;
              }
            `}
          >
            <Meme
              src="/assets/memes/manager.webp"
              $customStyles={`
                bottom: ${isLaptop ? 'unset' : '150px'};
                right: ${isLaptop ? '0' : '-120px'};
                top: ${isLaptop ? '0' : 'unset'};
                transform: rotate(12deg);
                width: ${isLaptop ? '177px' : '330px'};
                height: ${isLaptop ? '177px' : '330px'};
              `}
            />
            <TitleWrapper>
              <Subtitle style={{ maxWidth: '240px' }}>
                Possibility of Recruiting fee model for tech specialists
              </Subtitle>
              <Text
                $customStyles={`
                  max-width: 350px;
                  
                  @media${devices.maxLaptopL} {
                    max-width: 100%;
                  }
                `}
              >
                Expand your team effortlessly with our tech specialist recruitment services. We offer a
                transparent recruiting fee model that simplifies the hiring process and provides access to a
                wide network of talented professionals in the tech industry. Our extensive expertise and
                understanding of technical requirements enable us to source candidates that perfectly align
                with your project needs and organizational culture, ensuring a seamless incorporation into
                your existing teams.
              </Text>
            </TitleWrapper>
            <TitleWordsGroup
              $customStyles={`
                @media${devices.maxLaptopL} and ${devices.tablet} {
                   align-items: flex-start !important;
                   & > text {
                    font-size: 80px !important;
                    font-style: normal !important;
                    line-height: 102% !important;
                    letter-spacing: normal !important;
                   }
                }
            `}
            >
              <TitlePart>Possibility of</TitlePart>
              <TitlePart>Recruiting</TitlePart>
              <TitlePart>fee model</TitlePart>
              <TitlePart>for tech</TitlePart>
              <TitlePart>specialists</TitlePart>
            </TitleWordsGroup>
          </ArticleWrapper>
          <ArticleWrapper
            $row={!isLaptop}
            $customStyles={`
              justify-content: space-between;
            `}
          >
            <LastImages>
              {!isMobile && <ImageWrapper className="secondary-background" />}
              <ImageWrapper style={{ backgroundImage: 'url(/assets/article/blur.webp)' }} />
              {!isMobile && <ImageWrapper className="secondary-background" />}
              <ImageWrapper style={{ backgroundImage: 'url(/assets/article/chart.webp)' }} />
            </LastImages>
            <TitleWrapper>
              <Subtitle>Outsource/Outstaff Models of Work</Subtitle>
              <Text
                $customStyles={`
                  max-width: 600px;
                  
                  @media${devices.maxLaptopL} {
                    max-width: 100%;
                  }
                `}
              >
                Outsource: Our outsourcing model provides you with qualified specialists and teams who develop
                your projects from ideation to implementation. We manage the entire product development cycle,
                providing your business with high-quality solutions and allowing you to focus on the core
                aspects of your activities. Guided by your business requirements and goals, we work on
                creating products that deliver substantial business value and competitive advantages in the
                market.
              </Text>
              <Text
                $customStyles={`
                  max-width: 600px;
                `}
              >
                Outstaff: Our outstaffing model provides an opportunity to enhance your existing staff with
                specialists from our team who work for you remotely but are integrated as part of your team.
                This is an ideal option for organizations that require additional resources for their projects
                without the need to spend time and money on hiring and training new employees.
              </Text>
            </TitleWrapper>
          </ArticleWrapper>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Gazette);
