import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 184px;

  @media${devices.maxLaptopL} {
    padding-bottom: 100px;
  }

  @media${devices.maxTablet} {
    padding-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;

  @media${devices.maxLaptopL} {
    width: 90%;
    row-gap: 16px;
    column-gap: 15px;
  }

  @media${devices.maxTablet} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Item = styled.div.attrs({
  className: 'secondary-background primary-color strong-weight',
})`
  font-size: 30px;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 150px;
  height: 200px;
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media${devices.maxLaptopL} {
    height: 140px;
    width: 220px;
    font-size: 20px;
  }

  @media${devices.maxTablet} {
    width: 100%;
    height: 60px;
    font-size: 18px;
    padding: 0 20px;
  }
`;

export const BlankItem = styled.div`
  height: 200px;
  width: 330px;

  @media${devices.maxLaptopL} {
    height: 140px;
    width: 220px;
  }

  @media${devices.maxTablet} {
    display: none;
  }
`;
