import React from 'react';
import Welcome from 'common/components/Welcome';
import AboutUs from 'common/components/AboutUs';
import Cases from 'common/components/Cases';
import Ocean from 'common/components/Ocean';
import Feedbacks from 'common/components/Feedbacks';
import Ideas from 'common/components/Ideas';
import Memes from 'common/components/Memes';
import Faq from 'common/components/Faq';
import { Wrapper } from './styles';

const Main: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Welcome />
      <AboutUs />
      <Cases />
      <Ocean />
      <Feedbacks />
      <Ideas />
      <Memes />
      <Faq />
    </Wrapper>
  );
};

export default Main;
