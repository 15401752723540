import styled, { keyframes } from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div.attrs({
  className: 'primary-inverted-color primary-inverted-background',
})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;

  @media${devices.maxLaptopL} {
    width: 90%;
    flex-direction: column;
    gap: 80px;
  }

  @media${devices.maxTablet} {
    gap: 60px;
  }
`;

export const Addresses = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;

  @media${devices.maxLaptopL} {
    gap: 20px;
  }
`;

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media${devices.maxLaptopL} {
    flex-direction: row;
    gap: 50px;
  }
`;

const textAnimation = keyframes`
  0% {
    background-position: 0 30%;
  }

  25% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 60%;
  }

  75% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0 30%;
  }
`;

export const Email = styled.a.attrs({
  className: 'strong-weight',
})`
  font-size: 60px;
  line-height: 102%;

  background-clip: text;
  color: transparent !important;
  background-image: url('/assets/welcome-bg.webp');
  -webkit-background-clip: text;

  transition: all 0.3s;
  animation: ${textAnimation} 15s linear infinite;

  @media${devices.maxLaptopL} {
    font-size: 80px;
  }

  @media${devices.maxTablet} {
    font-size: 42px;
  }
`;

export const Address = styled.div.attrs({
  className: 'semi-bold-weight',
})`
  font-size: 24px;
  opacity: 0.5;

  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;

export const SocialNetwork = styled.a.attrs({
  className: 'strong-weight',
})`
  font-size: 60px;
  line-height: 102%;

  @media${devices.maxTablet} {
    font-size: 30px;
  }
`;
