import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div<{ $backgroundColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 66px;
  position: fixed;
  height: 66px;
  top: 0;
  z-index: 999;

  @media${devices.maxTablet} {
    height: 70px;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 30px;

  @media${devices.maxLaptop} {
    padding: 16px 20px;
  }

  @media${devices.maxTablet} {
    padding: 18px 20px;
  }
`;

export const MenuAndLogoWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media${devices.maxLaptop} {
    width: 55%;
  }

  @media${devices.maxTablet} {
    width: 100%;
    flex-direction: row-reverse;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Logo = styled.img``;

export const LogoText = styled.text.attrs({ className: 'standard-text strong-weight ' })<{
  $inverted: boolean;
}>`
  text-transform: uppercase;
  color: ${({ $inverted }) => ($inverted ? '#2f23f2' : '#FFFFFF')};
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MenuItem = styled.a.attrs({ className: 'small-text' })<{
  $active: boolean;
  $inverted: boolean;
}>`
  ${({ $active, $inverted }) => $active && $inverted && `color: #4439F3 !important;`}
  ${({ $active, $inverted }) => $active && !$inverted && `opacity: 0.5;`}
  & > text {
    margin-left: 10px;
  }
`;

export const MenuDivider = styled.text.attrs({ className: 'xxxsmall-text' })`
  opacity: 0.3;
`;

export const CallButton = styled.button.attrs({
  className: 'secondary-background small-text primary-color button-hover',
})`
  border-radius: 10px;
  border: 1px solid #fff;
  height: 32px;
  width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
