import styled from 'styled-components';
import { devices } from 'common/const';
import LetsTalk from 'common/components/LetsTalk';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;

  @media${devices.maxLaptopL} {
    padding: 60px 0 100px;
  }

  @media${devices.maxTablet} {
    padding: 60px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const CenterTitleWrapper = styled.div.attrs({ className: 'strong-weight' })`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 92px 40px;

  & text {
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    width: 50%;

    @media${devices.maxLaptopL} {
      font-size: 14px;
    }

    @media${devices.maxTablet} {
      max-width: 85px;
    }
  }

  @media${devices.maxLaptopL} {
    padding: 33px 36px;
  }

  @media${devices.maxTablet} {
    justify-content: center;
    gap: 80px;
    padding: 20px 36px;
  }
`;

export const LetsTalkButton = styled(LetsTalk)`
  margin-top: 60px;
  margin-bottom: 200px;
  cursor: pointer;

  @media${devices.maxLaptopL} {
    margin-bottom: 140px;
  }

  @media${devices.maxTablet} {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

export const ItemsWrapper = styled.div.attrs({ className: 'secondary-background' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 234px;
  border-radius: 20px;
  padding: 0 30px;
  justify-content: space-between;

  @media${devices.maxTablet} {
    padding: 0 16px;
    height: 306px;
  }
`;

export const ItemHeader = styled.div.attrs({
  className: 'primary-color',
})`
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media${devices.maxLaptopL} {
    padding-top: 20px;
  }

  @media${devices.maxTablet} {
    padding-top: 16px;
  }
`;

export const ItemImage = styled.img`
  width: 60px;
  height: 60px;
`;

export const ItemName = styled.div.attrs({
  className: 'xmedium-text strong-weight',
})`
  text-transform: uppercase;

  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;

export const ItemPosition = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})`
  opacity: 0.8;
`;

export const ItemBodyText = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})`
  width: 100%;
  max-width: 700px;
  text-align: center;

  padding-bottom: 30px;

  @media${devices.maxLaptopL} {
    padding-bottom: 20px;
  }

  @media${devices.maxTablet} {
    padding-bottom: 16px;
  }
`;

export const LabelWithOpacity = styled.div`
  opacity: 0.6;
`;

export const LabelWithBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid #fff;
  padding: 3px 12px;
`;
