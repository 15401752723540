import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media${devices.maxTablet} {
    height: auto;
    padding-top: 218px;
    padding-bottom: 149px;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 40px;
  }

  @media${devices.maxTablet} {
    gap: 0;
  }
`;

export const SingleTitleWrapper = styled.div<{ $positionEnd?: boolean }>`
  display: flex;

  @media${devices.maxLaptopL} {
    max-width: 690px;
    width: 100%;

    ${({ $positionEnd }) =>
      $positionEnd &&
      `
      justify-content: flex-end;
    `}
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight',
})<{ $tablet?: boolean }>`
  line-height: 102%;
  font-size: 80px;
  opacity: 0.4;
  text-transform: uppercase;

  @media${devices.maxLaptopL} {
    ${({ $tablet }) =>
      $tablet &&
      `
    font-size: 40px;
  `}
  }
`;

export const MobileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

export const MobileTitle = styled(Title)`
  font-size: 20px;
`;

export const MarqueeWrapper = styled.div`
  width: 100%;
  padding: 40px 0 20px;

  & .rfm-marquee-container {
    overflow: hidden;
  }
`;

export const MarqueeTitle = styled.text.attrs({
  className: 'strong-weight',
})`
  font-size: 50px;
  line-height: 102%;
  text-transform: uppercase;
`;
