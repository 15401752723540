import { memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import AboutItem from 'common/components/AboutUs/AboutItem';
import { useMedia } from 'common/hooks/useMedia';
import { AFFPRO_VIDEO, CRYPTO_WALLET_VIDEO, devices, MODERNACASA_VIDEO, RICO_VIDEO } from 'common/const';
import * as icecode from 'lotties/icecode.json';
import * as webDesign from 'lotties/webDesign.json';
import * as soft from 'lotties/soft.json';
import { Container, Wrapper } from './styles';

const AboutUs = () => {
  const { result: isTablet } = useMedia(devices.maxLaptopL);

  const items = [
    {
      title: 'App Development',
      description:
        'Our team specializes in mobile app development for iOS and Android platforms, dealing with all aspects of the process, from conception to deployment in app stores. We create engaging, intuitive, and reliable mobile apps that consider the specifics of your business and user goals.',
      mainImage: '/assets/about/logo.svg',
      lottie: icecode,
      imageWrapperRadius: '50%',
      cases: [
        {
          video: RICO_VIDEO,
          placeholder: '/assets/about/rico.webp',
        },
        {
          video: CRYPTO_WALLET_VIDEO,
          placeholder: '/assets/about/wallet.webp',
        },
        {
          video: '/assets/about/bus.webp',
          placeholder: '/assets/about/bus.webp',
        },
      ],
    },
    {
      title: 'Web Development',
      description:
        'In our Web Development department, we work to create high-quality, fast, and responsive websites tailored to individual client needs. Our portfolio includes corporate websites, e-commerce platforms, landing pages, and various web projects. Our specialists are equipped with the latest technologies and knowledge to bring the boldest ideas to life.',
      mainImage: '/assets/about/logo.svg',
      lottie: webDesign,
      imageWrapperRadius: isTablet ? '4px' : '20px',
      cases: [
        { video: MODERNACASA_VIDEO, placeholder: '/assets/about/casa.webp' },
        {
          video: AFFPRO_VIDEO,
          placeholder: '/assets/about/affpro.webp',
        },
      ],
    },
    {
      title: 'Soft Development',
      description:
        'Software development at our studio encompasses creating user software, systems, APIs, and other software solutions for enterprises and individual clients. From idea to implementation, our developers provide clients with powerful and efficient software products that assist them in achieving their business objectives.',
      imageWrapperRadius: isTablet ? '4px' : '120px',
      mainImage: '/assets/about/setting.png',
      lottie: soft,
    },
  ];

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          {items.map((item) => (
            <AboutItem key={item.title} {...item} />
          ))}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(AboutUs);
