import styled, { css } from 'styled-components';
import { devices } from 'common/const';
import PhoneInput from 'react-phone-number-input';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 150px;

  @media${devices.maxLaptopL} {
    padding-bottom: 60px;
  }
  @media${devices.maxTablet} {
    padding-bottom: 60px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div.attrs({
  className: 'strong-weight xhuge-text',
})`
  color: #2f23f2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 100px;

  @media${devices.maxLaptopL} {
    margin-bottom: 60px;
  }
  @media${devices.maxTablet} {
    font-size: 80px;
    margin-bottom: 40px;
  }
`;

export const Form = styled.form`
  max-width: 914px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media${devices.maxTablet} {
    gap: 10px;
  }
`;

export const InputLine = styled.div`
  display: flex;
  gap: 20px;

  @media${devices.maxTablet} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Input = styled.input`
  height: 64px;
  border: none;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  width: 100%;

  font-size: 20px;
  font-weight: 500;

@media${devices.maxTablet} {
  font-size: 16px;
  height: 48px;
}
}
`;

export const PhoneNumberInput = styled(PhoneInput)`
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  gap: 10px;
  background: #f8f8f8;
  border-radius: 10px;
  padding-left: 20px;

  .PhoneInputCountry {
    height: fit-content;
    display: flex;
    align-items: center;
    background: #f8f8f8;

    & select {
      display: none;
    }

    & div,
    img {
      height: 20px;
    }

    & img {
      width: 20px;
      height: 20px;
    }
  }

  & input {
    border: none;
    background: #f8f8f8;
    font-size: 20px;
    font-weight: 500;
    height: 100%;
    width: 100%;

    @media${devices.maxTablet} {
      font-size: 16px;
      height: 48px;
    }
  }
`;

export const TextInput = styled.textarea`
  resize: none;
  height: 100px;
  border: none;
  background: #f8f8f8;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  font-family:
    Instrument Sans SemiCondensed,
    sans-serif !important;

  font-size: 20px;
  font-weight: 500;

  @media${devices.maxTablet} {
    font-size: 16px;
    height: 96px;
  }
`;

export const FileInput = styled.div`
  height: 100px;
  border-radius: 10px;
  background: none;
  border: 1px dashed #000;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  @media${devices.maxTablet} {
    height: 90px;
  }
`;

export const FileTitle = styled.text.attrs({
  className: 'medium-text semi-bold-weight primary-inverted-color',
})`
  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;

export const FileText = styled.text.attrs({
  className: 'small-text semi-bold-weight primary-inverted-color',
})`
  opacity: 0.3;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

export const Button = styled.button.attrs({
  className: 'secondary-background xmedium-text primary-color strong-weight button-hover-border',
})`
  border: none;
  display: flex;
  width: 214px;
  height: 62px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  &:disabled {
    &:hover {
      background-color: #4439f3;
      color: #ffffff !important;
      border: none;
      cursor: not-allowed;
    }
  }

  @media${devices.maxTablet} {
    width: 100%;
  }
`;

export const CrossIcon = styled.img.attrs({
  src: '/assets/crossBlue.svg',
})`
  cursor: pointer;
  display: inline;
  margin-left: 10px;
`;

export const FileStatus = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})<{ $failed: boolean }>`
  color: ${({ $failed }) => ($failed ? '#F24823' : '#2F23F2')};
`;
