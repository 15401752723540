import { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { SwiperSlide } from 'swiper/react';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import MobileSwiper from 'common/components/MobileSwiper';
import { Container, Wrapper, Title, CasesWrapper, CasesContainer } from './styles';
import Case from './Case';

const DesverseCases: FunctionComponent = () => {
  const { result: isMobile } = useMedia(devices.maxTablet);
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleOffsetLeft, setTitleOffsetLeft] = useState<number>(0);

  const cases = useMemo(
    () => [
      { image: '/assets/desverse/cases/wallet.webp', name: 'Crypto Wallet' },
      { image: '/assets/desverse/cases/affpro.webp', name: 'AFFPRO' },
      { image: '/assets/desverse/cases/zepuff.webp', name: 'ZEPUFF' },
      { image: '/assets/desverse/cases/rico.webp', name: 'RICO APP' },
      { image: '/assets/desverse/cases/modernacasa.webp', name: 'MODERNACASA' },
      { image: '/assets/desverse/cases/lixb.webp', name: 'LIXB RIDESHARE' },
    ],
    [],
  );

  useEffect(() => {
    if (!titleRef.current) return;
    setTitleOffsetLeft((window.innerWidth - titleRef.current.offsetWidth) / 2);
  }, []);

  return (
    <Fragment>
      <Container>
        <DesktopContentWrapper>
          <Wrapper>
            <Title ref={titleRef}>case study of DESVERSE</Title>
            {isMobile && (
              <MobileSwiper
                invertedButtons
                items={cases.map((item) => (
                  <SwiperSlide key={item.name}>
                    <Case {...item} />
                  </SwiperSlide>
                ))}
              />
            )}
          </Wrapper>
        </DesktopContentWrapper>
      </Container>
      <CasesContainer $paddingLeftDesktop={titleOffsetLeft}>
        <CasesWrapper>
          {cases.map((item) => (
            <Case key={item.name} {...item} />
          ))}
        </CasesWrapper>
      </CasesContainer>
    </Fragment>
  );
};

export default DesverseCases;
