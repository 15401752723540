import React, { Fragment, useState } from 'react';
import { motion } from 'framer-motion';
import { BigImage, BodyText, IconCross, Technologies, Text, Title, Wrapper, Link } from './styles';

type Props = {
  title: string;
  bigImage: string;
  technologies: string[];
  hours: number;
  industry: string;
  text: string;
  link: string;
};

const CaseItem: React.FunctionComponent<Props> = ({
  technologies,
  industry,
  bigImage,
  hours,
  title,
  text,
  link,
}) => {
  const [isTextOpened, setIsTextOpened] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);

  const formattedLink = link.split('//')[1];

  const toggleText = () => {
    if (linkHovered) return;
    setIsTextOpened((prev) => !prev);
  };

  const motionProps = {
    initial: { rotateY: '180deg' },
    animate: { rotateY: 0 },
    transition: { duration: 0.3 },
  };

  return (
    <Fragment>
      {isTextOpened ? (
        <motion.div key={`${text}_${title}`} {...motionProps}>
          <Wrapper onClick={toggleText}>
            <IconCross />
            {link && (
              <Link
                onMouseEnter={() => setLinkHovered(true)}
                onMouseLeave={() => setLinkHovered(false)}
                href={link}
              >
                {formattedLink}
              </Link>
            )}
            <BodyText>{text}</BodyText>
          </Wrapper>
        </motion.div>
      ) : (
        <motion.div key={title} {...motionProps}>
          <Wrapper style={{ minWidth: '360px' }} onClick={toggleText}>
            <Text>
              {hours}h / {industry}
            </Text>
            <BigImage src={bigImage} />
            <Title>{title}</Title>
            <Technologies>
              {technologies.map((item) => (
                <img key={item} src={item} alt={item} />
              ))}
            </Technologies>
            <Text>{hours}h</Text>
          </Wrapper>
        </motion.div>
      )}
    </Fragment>
  );
};

export default CaseItem;
