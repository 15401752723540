import { FunctionComponent, memo, useMemo, useState } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import Benefits from 'common/components/Memes/Benefits';
import DraggableImage from 'common/components/DraggableImage';
import { Container, MemesWrapper, Wrapper } from './styles';

const Memes: FunctionComponent = () => {
  const [selectedImage, setSelectedImage] = useState<string>();

  const memes = useMemo(
    () => [
      {
        image: '/assets/memes/recursion.webp',
        position: { desktop: { x: 100, y: 0 }, tablet: { x: 0, y: 0 }, mobile: { x: -15, y: 0 } },
        style: { rotate: '15deg' },
        sizes: {
          desktop: {
            width: '482px',
            height: '350px',
          },
          tablet: {
            width: '340px',
            height: '248px',
          },
          mobile: {
            width: '214px',
            height: '156px',
          },
        },
      },
      {
        image: '/assets/memes/robots.webp',
        position: { desktop: { x: 800, y: 125 }, tablet: { x: 300, y: 71 }, mobile: { x: 160, y: 153 } },
        sizes: {
          desktop: {
            width: '482px',
            height: '350px',
          },
          tablet: {
            width: '340px',
            height: '248px',
          },
          mobile: {
            width: '214px',
            height: '156px',
          },
        },
      },
      {
        image: '/assets/memes/junior.webp',
        position: { desktop: { x: 50, y: 360 }, tablet: { x: 0, y: 260 }, mobile: { x: 165, y: 20 } },
        style: { rotate: '-15deg' },
        sizes: {
          desktop: {
            width: '482px',
            height: '350px',
          },
          tablet: {
            width: '340px',
            height: '248px',
          },
          mobile: {
            width: '214px',
            height: '156px',
          },
        },
      },
      {
        image: '/assets/memes/manager.webp',
        position: { desktop: { x: 500, y: 300 }, tablet: { x: 350, y: 250 }, mobile: { x: 0, y: 130 } },
        sizes: {
          desktop: {
            width: '350px',
            height: '350px',
          },
          tablet: {
            width: '248px',
            height: '248px',
          },
          mobile: {
            width: '156px',
            height: '156px',
          },
        },
      },
    ],
    [],
  );

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Benefits />
          <MemesWrapper>
            {memes.map((meme) => (
              <DraggableImage
                src={meme.image}
                sizes={meme.sizes}
                onTop={selectedImage === meme.image}
                onDrag={() => setSelectedImage(meme.image)}
                position={meme.position}
                customStyles={meme?.style}
              />
            ))}
          </MemesWrapper>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Memes);
