import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  min-width: 330px;
  gap: 10px;
`;

export const Header = styled.div.attrs({
  className: 'primary-color secondary-background',
})`
  width: 100%;
  display: flex;
  height: 110px;
  border-radius: 20px;
  align-items: center;
  padding: 0 20px;
  gap: 14px;

  @media${devices.maxTablet} {
    height: 74px;
    width: auto;
  }
`;

export const Image = styled.img`
  width: 70px;
  height: 70px;

  @media${devices.maxTablet} {
    width: 46px;
    height: 46px;
  }
`;

export const Name = styled.div.attrs({
  className: 'xmedium-text strong-weight',
})`
  text-transform: uppercase;

  @media${devices.maxTablet} {
    font-size: 16px;
  }
`;

export const Position = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})`
  opacity: 0.8;
`;

export const BodyText = styled.div.attrs({
  className: 'small-text semi-bold-weight',
})`
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background: #f8f8f8;
  text-align: center;

  @media${devices.maxTablet} {
    width: auto;
  }
`;
