import { memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import {
  Container,
  Wrapper,
  ContentWrapper,
  Date,
  Title,
  BlockText,
  Block,
  BlockTextParagraphs,
  BlockTitle,
} from './styles';

const LAST_UPDATE_DATE = 'Oct 24, 2023';

const PrivacyPolicy = () => {
  const blocks = [
    {
      paragraphs: [
        'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
        'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.',
      ],
    },
    {
      title: 'Interpretation and Definitions',
      paragraphs: [
        'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
      ],
    },
    {
      title: 'Definitions',
      paragraphs: [
        'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
      ],
    },
    {
      title: 'Tracking and Performance Cookies',
      paragraphs: [
        'Tracking and Performance Cookies',
        'Administered by: Third-Parties',
        'Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.',
      ],
    },
    {
      title: 'Personal Data',
      paragraphs: [
        'The Company may use Personal Data for the following purposes:',
        'To provide and maintain our Service, including to monitor the usage of our Service.',
        'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
        'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
      ],
    },
    {
      title: 'Retention of Your Personal Data',
      paragraphs: [
        'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
        'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
      ],
    },
    {
      title: 'Disclosure of Your Personal Data',
      paragraphs: [
        'Business Transactions',
        'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
        'Law Enforcement',
        'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
      ],
    },
  ];

  return (
    <Wrapper>
      <Container>
        <DesktopContentWrapper>
          <ContentWrapper>
            <Date>Updated: {LAST_UPDATE_DATE}</Date>
            <Title>policy & privacy</Title>
            {blocks.map((block, index) => (
              <Block key={index}>
                {block.title && <BlockTitle>{block.title}</BlockTitle>}
                <BlockTextParagraphs>
                  {block.paragraphs.map((paragraph, index) => (
                    <BlockText key={index}>{paragraph}</BlockText>
                  ))}
                </BlockTextParagraphs>
              </Block>
            ))}
          </ContentWrapper>
        </DesktopContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default memo(PrivacyPolicy);
