import { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import CustomMarquee from 'common/components/CustomMarquee';
import {
  Container,
  MobileRow,
  MobileTitle,
  SingleTitleWrapper,
  Title,
  Wrapper,
  MarqueeTitle,
  MarqueeWrapper,
} from './styles';
import CenterBlock from './CenterBlock';

const SquareWelcome: FunctionComponent = () => {
  const { result: isTablet } = useMedia(devices.maxLaptopL);
  const { result: isMobile } = useMedia(devices.maxTablet);

  const marqueeItems = [
    <MarqueeTitle style={{ color: '#4439F3' }}>Development</MarqueeTitle>,
    <MarqueeTitle className="primary-inverted-color">Development</MarqueeTitle>,
  ];

  return (
    <Container>
      {isMobile && (
        <MarqueeWrapper>
          <CustomMarquee items={marqueeItems} />
        </MarqueeWrapper>
      )}
      <DesktopContentWrapper>
        <Wrapper>
          {!isMobile && (
            <SingleTitleWrapper>
              <Title $tablet={isTablet}>Web</Title>
            </SingleTitleWrapper>
          )}
          <CenterBlock />
          {isMobile && (
            <MobileRow>
              <MobileTitle $tablet={isTablet}>Web</MobileTitle>
              <MobileTitle $tablet={isTablet}>Soft</MobileTitle>
            </MobileRow>
          )}
          {!isMobile && (
            <SingleTitleWrapper $positionEnd={isTablet}>
              <Title $tablet={isTablet}>Soft</Title>
            </SingleTitleWrapper>
          )}
        </Wrapper>
      </DesktopContentWrapper>
      {isMobile && (
        <MarqueeWrapper>
          <CustomMarquee items={marqueeItems} direction={'right'} />
        </MarqueeWrapper>
      )}
    </Container>
  );
};

export default memo(SquareWelcome);
