import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { Logo, LogoText, LogoWrapper } from 'common/components/Header/styles';
import { paths } from 'common/const/urls';
import {
  CURRENT_DATE,
  DESVERSE_BEHANCE,
  DESVERSE_DRIBBLE,
  DESVERSE_URL,
  devices,
  INSTAGRAM,
  LINKED_IN,
  REGISTRATION_ADDRESS,
  REGISTRATION_COUNTRY,
  SUPPORT_EMAIL,
} from 'common/const';
import { useMedia } from 'common/hooks/useMedia';
import {
  Container,
  Wrapper,
  LogoLine,
  LinksWrapper,
  Column,
  Title,
  Label,
  MenuLine,
  PartnersWrapper,
  PartnersLine,
  PartnersItem,
  PolicyLine,
  Address,
  PrivacyPolicy,
} from './styles';

const Footer: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { result: isTablet } = useMedia(devices.maxLaptopL);

  const menu = [
    {
      name: 'Main speciality',
      path: paths.MAIN_SPECIALITY,
    },
    {
      name: 'Additional',
      path: paths.ADDITIONAL,
    },
    {
      name: 'Custom',
      path: paths.CUSTOM,
    },
  ];

  const partners = [
    { image: '/assets/partners/desverse.svg', link: DESVERSE_URL },
    { image: '/assets/partners/dribble.svg', link: DESVERSE_DRIBBLE },
    { image: '/assets/partners/behance.svg', link: DESVERSE_BEHANCE },
  ];

  const goToMain = () => {
    if (location.pathname !== paths.MAIN) {
      navigate(paths.MAIN);
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <LogoLine>
            <LogoWrapper onClick={goToMain}>
              <Logo src="/assets/logo-white.svg" />
              <LogoText $inverted={false}>IceCode</LogoText>
            </LogoWrapper>
          </LogoLine>
          <MenuLine>
            <LinksWrapper>
              <Column>
                <Title>Navigation</Title>
                {menu.map(({ name, path }) => (
                  <Label key={name} href={path}>
                    {name}
                  </Label>
                ))}
              </Column>
              <Column>
                <Title>Social media</Title>
                <Label href={INSTAGRAM} target="_blank">
                  Instagram
                </Label>
                <Label href={LINKED_IN} target="_blank">
                  Linkedin
                </Label>
              </Column>
              <Column>
                <Title>Contact us</Title>
                <Label href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Label>
              </Column>
            </LinksWrapper>
            {!isTablet && (
              <PartnersWrapper>
                <Title>check our partners Desverse</Title>
                <PartnersLine>
                  {partners.map((p) => (
                    <PartnersItem key={p.image} href={p.link}>
                      <img src={p.image} alt={p.image} />
                    </PartnersItem>
                  ))}
                </PartnersLine>
              </PartnersWrapper>
            )}
          </MenuLine>
          <PolicyLine>
            <Address>
              <Title>{`IceCode ${CURRENT_DATE} ©`}</Title>
              <div>
                <Label>{REGISTRATION_COUNTRY}</Label>
                <Label>{REGISTRATION_ADDRESS}</Label>
              </div>
            </Address>
            <PrivacyPolicy>
              <Label href={paths.PRIVACY_POLICY}>Privacy policy</Label>
            </PrivacyPolicy>
          </PolicyLine>
          {isTablet && (
            <PartnersWrapper>
              <Title>check our partners Desverse</Title>
              <PartnersLine>
                {partners.map((p) => (
                  <PartnersItem key={p.image} href={p.link}>
                    <img src={p.image} alt={p.image} />
                  </PartnersItem>
                ))}
              </PartnersLine>
            </PartnersWrapper>
          )}
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default Footer;
