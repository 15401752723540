import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-background primary-inverted-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 200px;

  @media${devices.maxLaptopL} {
    padding-bottom: 146px;
  }

  @media${devices.maxTablet} {
    padding-top: 60px;
    padding-bottom: 105px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxTablet} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight huge-text',
})`
  text-transform: uppercase;
  margin-bottom: 30px;

  @media${devices.maxTablet} {
    font-size: 40px;
    margin-bottom: 14px;
  }
`;

export const SubTitle = styled.div.attrs({
  className: 'standard-text',
})`
  text-transform: uppercase;
  opacity: 0.8;
  max-width: 538px;
  text-align: center;
  margin-bottom: 120px;

  @media${devices.maxLaptopL} {
    margin-bottom: 80px;
  }

  @media${devices.maxTablet} {
    font-size: 14px;
    margin-bottom: 23px;
  }
`;

export const CasesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media${devices.maxLaptopL} {
    justify-content: center;
  }
`;
