import { useMediaQuery } from 'react-responsive';
import { devices } from 'common/const';

export const useMedia = (query?: string) => {
  const result = useMediaQuery({ query });

  const isMobile = useMediaQuery({
    query: devices.maxTablet,
  });
  const isTablet = useMediaQuery({
    query: `${devices.tablet} and ${devices.maxLaptop}`,
  });
  const isLaptopL = useMediaQuery({
    query: `${devices.laptop} and ${devices.maxLaptopL}`,
  });
  const isDesktop = useMediaQuery({ query: devices.laptopL });

  return {
    isMobile,
    isTablet,
    isLaptopL,
    isDesktop,
    result,
  };
};
