import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-inverted-color primary-inverted-background',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 0;

  @media${devices.maxLaptopL} {
    padding: 100px 0;
  }
  @media${devices.maxTablet} {
    padding: 60px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({ className: 'xhuge-text strong-weight' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin-bottom: 100px;

  @media${devices.maxLaptopL} {
    font-size: 100px;
    margin-bottom: 60px;
  }
  @media${devices.maxTablet} {
    font-size: 48px;
    margin-bottom: 50px;
  }
`;

export const FilteredListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 72px;

  @media${devices.maxLaptopL} {
    gap: 16px;
    margin-bottom: 52px;
  }

  @media${devices.maxTablet} {
    margin-bottom: 22px;
    gap: 10px;
  }
`;

export const Filter = styled.button.attrs({
  className: 'strong-weight xmedium-text secondary-background primary-color',
})<{
  $isActive: boolean;
}>`
  cursor: pointer;
  text-transform: uppercase;
  width: 215px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  ${({ $isActive }) =>
    !$isActive &&
    `
  background-color: #fff; 
  font-weight: 500; 
  color: #000000 !important;
  border: 1px solid rgba(0, 0, 0, 0.04);
  `}

  @media${devices.maxLaptopL} {
    width: 164px;
    height: 50px;
  }

  @media${devices.maxTablet} {
    width: 155px;
    height: 40px;
    border-radius: 14px;
    font-size: 16px;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  overflow-x: hidden;
  padding: 8px 0;

  @media${devices.maxLaptopL} {
    gap: 16px;
  }

  @media${devices.maxTablet} {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 373px;
  height: 172px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  padding: 30px 51px;
  background-color: #0000000a;

  & text {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
  }

  @media${devices.maxLaptopL} {
    padding: 20px 26px;
  }

  @media${devices.maxTablet} {
    width: 100%;
  }
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

export const CallButton = styled.button.attrs({
  className: 'secondary-background standard-text semi-bold-weight primary-color',
})`
  display: flex;
  width: 128px;
  height: 128px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 52px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  @media${devices.maxLaptopL} {
    display: none;
  }
`;
