import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media${devices.maxTablet} {
    width: 100%;
  }
`;

export const Image = styled.img`
  margin-bottom: 20px;
  border-radius: 15px;
  border: 5px solid #4439f3;

  object-fit: cover;

  width: 573px;
  height: 457px;
  max-width: 573px;
  max-height: 457px;

  @media${devices.maxLaptopL} {
    width: 448px;
    height: 356px;
    max-width: 448px;
    max-height: 356px;
    border-radius: 19px;
  }
  @media${devices.maxTablet} {
    height: auto;
    margin-bottom: 10px;
    width: auto;
  }
`;

export const Button = styled.div.attrs({
  className: 'primary-color strong-weight secondary-background',
})`
  text-transform: uppercase;
  font-size: 24px;
  border-radius: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  cursor: pointer;

  @media${devices.maxTablet} {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 10px 10px 20px;
    width: auto;
  }
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;

  @media${devices.maxTablet} {
    width: 30px;
    height: 30px;
  }
`;
