import { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { useMedia } from 'common/hooks/useMedia';
import { DESVERSE_VIDEO, devices } from 'common/const';
import VideoComponent from 'common/components/VideoComponent';
import { Container, Title, Wrapper } from './styles';

const DesverseLogo: FunctionComponent = () => {
  const { isMobile, isTablet, isLaptopL } = useMedia(devices.maxLaptopL);

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <VideoComponent
            url={DESVERSE_VIDEO}
            placeholder="/assets/desverse/logo.webp"
            width={isTablet || isLaptopL ? '219px' : isMobile ? '78px' : '328px'}
            height={isTablet || isLaptopL ? '128px' : isMobile ? '45px' : '164px'}
            borderRadius="100px"
            aspectRatio={isTablet || isLaptopL ? '219/128' : isMobile ? '219/128' : '3.3/1.8'}
            iframeFullWidth
          />
          <Title>
            <div>Our Design</div>
            <div>Partner Desverse</div>
          </Title>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(DesverseLogo);
