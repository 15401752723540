import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-color primary-background',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0 200px;

  @media${devices.maxLaptopL} {
    padding: 100px 0 160px;
  }

  @media${devices.maxTablet} {
    overflow: hidden;
    padding: 60px 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({ className: 'xhuge-text strong-weight' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin-bottom: 120px;

  @media${devices.maxLaptopL} {
    font-size: 90px;
    margin-bottom: 80px;
  }
  @media${devices.maxTablet} {
    font-size: 48px;
    margin-bottom: 40px;
  }
`;

export const ModelTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media${devices.maxLaptopL} {
    gap: 14px;
  }
`;

export const ModelTitle = styled.text.attrs({
  className: 'strong-weight',
})`
  font-size: 24px;
  line-height: normal;
  text-transform: uppercase;

  @media${devices.maxLaptopL} {
    font-size: 20px;
  }
`;

export const ModelSubtitle = styled.text.attrs({ className: 'standard-text semi-bold-weight' })`
  max-width: 198px;

  @media${devices.maxLaptopL} {
    font-size: 14px;
  }
`;

export const LetsTalkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
