import Marquee from 'react-fast-marquee';
import { FunctionComponent } from 'react';

type Props = {
  items: any[];
  direction?: 'left' | 'right' | 'down';
};

const CustomMarquee: FunctionComponent<Props> = ({ items, direction }) => {
  return (
    <Marquee autoFill play loop={0} speed={20} gradient={false} pauseOnClick={false} direction={direction}>
      {items}
    </Marquee>
  );
};

export default CustomMarquee;
