import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;

  @media${devices.maxTablet} {
    padding-bottom: 100px;
  }
`;

export const CasesContainer = styled(Container)<{ $paddingLeftDesktop: number }>`
  padding-bottom: 200px;
  justify-content: flex-start;

  padding-left: ${({ $paddingLeftDesktop }) => $paddingLeftDesktop}px;

  @media${devices.maxLaptopL} {
    padding-bottom: 140px;
    padding-left: 5%;
  }

  @media${devices.maxTablet} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight',
})`
  width: 100%;
  text-transform: uppercase;
  font-size: 80px;
  margin-bottom: 60px;

  @media${devices.maxTablet} {
    font-size: 44px;
    margin-bottom: 40px;
  }
`;

export const CasesWrapper = styled.div`
  display: flex;
  gap: 60px;
  overflow-x: auto;
  overflow-y: hidden;

  @media${devices.maxLaptopL} {
    gap: 26px;
  }
`;
