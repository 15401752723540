import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 200px;

  @media${devices.maxLaptopL} {
    padding-bottom: 160px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${devices.maxLaptopL} {
    width: 90%;
  }
`;

export const Title = styled.div.attrs({
  className: 'strong-weight',
})`
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 140px;
  font-size: 80px;

  @media${devices.maxLaptopL} {
    margin-bottom: 80px;
  }
  @media${devices.maxTablet} {
    font-size: 40px;
    margin-bottom: 60px;
  }
`;

export const CenterTitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 14px;

  @media${devices.maxLaptopL} {
    gap: 8px;
  }

  @media${devices.maxTablet} {
    padding: 12px 30px;
  }
`;

export const TextItem = styled.div.attrs({
  className: 'semi-bold-weight',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  border-radius: 40px;
  border: 1px solid #fff;

  font-size: 22px;
  line-height: normal;

  @media${devices.maxLaptopL} {
    font-size: 14px;
  }
`;
