import { FunctionComponent, memo } from 'react';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { JourneyTemplateProps } from 'types';
import JourneyTemplate from 'common/components/JourneyTemplate';
import { Container, Title, Wrapper, CenterTitleWrapper, TextItem } from './styles';

const DesverseJourney: FunctionComponent = () => {
  const journeyTemplateConfig = {
    title: 'Design',
    sideTitles: { left: 'Research', right: 'Development' },
    columns: {
      left: {
        title: 'Product discovery',
        rowGroups: [['3-8 weeks'], ['Visual proof of concept, cost estimation and delivery plan']],
      },
      center: {
        titleElement: (
          <CenterTitleWrapper>
            <TextItem>UI/UX</TextItem>
            <TextItem>Design systems</TextItem>
            <TextItem>Design systems</TextItem>
            <TextItem>User testing</TextItem>
          </CenterTitleWrapper>
        ),
        columns: [
          {
            rowGroups: [['8-12 weeks'], ['Design handoff (development-ready)']],
          },
        ],
      },
      right: {
        title: 'Software engineering',
        rowGroups: [['12+ weeks'], [<TextItem style={{ fontSize: '16px' }}>MVP</TextItem>]],
      },
    },
  } as JourneyTemplateProps;

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>case study of DESVERSE</Title>
          <JourneyTemplate {...journeyTemplateConfig} />
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(DesverseJourney);
