import styled from 'styled-components';
import { devices } from 'common/const';

export const Container = styled.div.attrs({
  className: 'primary-background primary-color',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 216px 0 200px;
  overflow: hidden;

  @media${devices.maxLaptopL} {
    padding: 100px 0 160px;
  }

  @media${devices.maxTablet} {
    padding: 110px 0 85px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: column;

  @media${devices.maxLaptopL} {
    align-items: center;
    gap: 16px;
    width: 90%;
  }
`;

export const MemesWrapper = styled.div`
  height: 660px;
  width: 100%;

  @media${devices.maxTablet} {
    height: 360px;
  }
`;
