import React from 'react';
import { Wrapper, Header, Image, Name, Position, BodyText } from './styles';

type Props = {
  image: string;
  name: string;
  position: string;
  text: string;
};

const FeedbackItem: React.FunctionComponent<Props> = ({ image, text, name, position }) => {
  return (
    <Wrapper>
      <Header>
        <Image src={image} alt={image} />
        <div>
          <Name>{name}</Name>
          <Position>{position}</Position>
        </div>
      </Header>
      <BodyText>{text}</BodyText>
    </Wrapper>
  );
};

export default FeedbackItem;
