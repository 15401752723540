import styled from 'styled-components';
import { devices } from 'common/const';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div.attrs({
  className: 'primary-inverted-color primary-inverted-background',
})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  @media${devices.maxLaptopL} {
    width: 90%;
  }

  @media${devices.maxTablet} {
    gap: 50px;
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media${devices.maxTablet} {
    display: none;
  }
`;

export const Text = styled.text.attrs({
  className: 'semi-bold-weight',
})`
  font-size: 30px;
  line-height: normal;
  max-width: 180px;
`;

export const Button = styled.button.attrs({
  className: 'small-text secondary-background primary-color',
})`
  max-width: 103px;
  border-radius: 10px;
  padding: 8px 10px;

  @media${devices.maxTablet} {
    max-width: unset;
    padding: 14px 36px;
  }
`;
