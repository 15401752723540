import { FunctionComponent, memo, useState } from 'react';
import Expand from 'react-expand-animated';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/const';
import { DesktopContentWrapper } from 'common/components/PageLayout/styles';
import { questions } from './const';
import {
  Container,
  Wrapper,
  Title,
  Questions,
  ItemTitle,
  ItemBody,
  ItemText,
  ButtonWrapper,
  Item,
  Image,
  MobileImage,
} from './styles';

const Faq: FunctionComponent = () => {
  const { result: isTablet } = useMedia(devices.maxTablet);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const faqItems = questions.map((q) => {
    const isHidden = activeItem !== q.title;
    return (
      <Item
        key={q.title}
        onClick={() => {
          if (!isHidden) setActiveItem(null);
          else setActiveItem(q.title);
        }}
      >
        <ItemBody>
          <ItemTitle $isHidden={isHidden}>{q.title}</ItemTitle>
          <Expand open={!isHidden} duration={300}>
            <ItemText>{q.text}</ItemText>
          </Expand>
          {isTablet && (
            <MobileImage
              src="/assets/arrow-down.svg"
              style={{ transform: !isHidden ? 'rotate(180deg)' : 'rotate(0)' }}
            />
          )}
        </ItemBody>
        {!isTablet && (
          <ButtonWrapper $isHidden={isHidden}>
            <Image src={isHidden ? '/assets/arrow-down.svg' : '/assets/cross.svg'} />
          </ButtonWrapper>
        )}
      </Item>
    );
  });

  return (
    <Container>
      <DesktopContentWrapper>
        <Wrapper>
          <Title>FAQ</Title>
          <Questions>{faqItems}</Questions>
        </Wrapper>
      </DesktopContentWrapper>
    </Container>
  );
};

export default memo(Faq);
